import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from '@chocolate-soup-inc/cs-frontend-components';
import { APIProvider } from './contexts/api';
import { FilterProvider } from './contexts/filters';
import { AppRoutes } from './routes';
import './config/auth';
import './config/storage';
import { ToastContainer } from 'react-toastify';

import '@chocolate-soup-inc/cs-frontend-components/index.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <AuthProvider>
      <APIProvider>
        <FilterProvider>
          <ToastContainer autoClose={10000} position='top-center' hideProgressBar={true} theme='dark' />
          <AppRoutes />
        </FilterProvider>
      </APIProvider>
    </AuthProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.debug);
