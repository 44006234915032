import { getDateWithTimezoneFixed } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { GetOfficeDocument, TAddressParentTypes, TDependantType, TGiftTypes } from '../../generated/graphql';
import { client } from '../../config/apollo/api';
import { isDependant } from '../recipients/shared';
import { PDFDocument, StandardFonts } from 'pdf-lib';
import format from 'date-fns/format';
import { getGiftEventType } from './utils';
import { TGroupedGiftType } from './queries';

export type TGetGiftInfoSupportProps = {
  type: TGiftTypes;
};

export const getRemovedInfoFromRecipientData = (gift: any) => {
  const recipientData = JSON.parse(gift?.recipientData ?? '{}');
  if (recipientData.fullName) return `${recipientData.fullName} (Removed)`;
  if (!recipientData.firstName && !recipientData.lastName) return '-';
  return `${recipientData.firstName ?? '[No first name found]'} ${
    recipientData.lastName ?? '[No last name found]'
  } (Removed)`;
};

export const getIconFromGiftType = ({ type }: TGetGiftInfoSupportProps) => {
  switch (type) {
    case TGiftTypes.Birthday:
      return 'cake';
    case TGiftTypes.WorkAnniversary:
      return 'work';
    case TGiftTypes.NewHire:
      return 'person_add';
    default:
      return 'star';
  }
};

export const getReadableGiftType = ({ type }: TGetGiftInfoSupportProps) => {
  switch (type) {
    case TGiftTypes.Birthday:
      return 'Birthday';
    case TGiftTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TGiftTypes.NewHire:
      return 'New Hire';
    case TGiftTypes.BabyGift:
      return 'Baby Gift';
    case TGiftTypes.Wedding:
      return 'Wedding/Engagement';
    case TGiftTypes.ReplacementWorkAnniversary:
      return 'Replacement Work Anniversary';
    case TGiftTypes.ReplacementEmployeeBirthday:
      return 'Replacement Employee Birthday';
    case TGiftTypes.CarePackage:
      return 'Care Package';
    case TGiftTypes.CustomBricks:
      return 'Custom Bricks';
    case TGiftTypes.Other:
      return 'Other';
  }
};

type TIsDonationGiftProps = {
  gift: TGroupedGiftType;
};

export const isDonationGift = (props: TIsDonationGiftProps) => {
  const { gift } = props;
  let { recipient } = gift;

  if (recipient == null) {
    recipient = gift.recipient || undefined;
  }

  if (recipient == null) return false;

  if (
    !isDependant(recipient) &&
    ((gift.type === TGiftTypes.Birthday && recipient.donateBirthdayGift) ||
      (gift.type === TGiftTypes.WorkAnniversary && recipient.donateWorkAnniversaryGift))
  ) {
    return true;
  }

  if (
    isDependant(recipient) &&
    recipient.type === TDependantType.SignificantOther &&
    gift.type === TGiftTypes.Birthday &&
    recipient.donateBirthdayGift
  ) {
    return true;
  }

  return false;
};

type TPrintGiftLabelsProps = {
  gifts: TGroupedGiftType[];
};

export const printGiftLabels = async (props: TPrintGiftLabelsProps) => {
  const { gifts } = props;

  const docs = await Promise.all(
    gifts.map(async (gift) => {
      const { companyId, recipient, address, eventDate } = gift;
      const { name: companyName } = gift.company || {};
      let officeName: string | undefined;
      let recipientName: string | undefined;
      let employeeName: string | undefined;
      let recipientOfficeId: string | undefined;

      if (recipient != null) {
        recipientName = recipient.fullName.replace(/\s\([^)]+\)/gi, '');
        if (isDependant(recipient)) {
          const employee = recipient.employee;
          if (employee) employeeName = employee.fullName.replace(/\s\([^)]+\)/gi, '');
        } else {
          recipientOfficeId = recipient.officeId ?? undefined;
        }
      }

      if (address?.parentType === TAddressParentTypes.Office || address?.parentType === TAddressParentTypes.Company) {
        const officeId = address.id.split('|')[1];
        const { data } = await client.query({
          query: GetOfficeDocument,
          variables: {
            id: recipientOfficeId ?? officeId,
            companyId,
          },
        });
        officeName = data?.getOffice?.name;
      }

      const pageWidth = 54;
      const pageHeight = 25;
      const marginX = 1;
      const marginY = 1;
      const ySpace = 4;
      const fontSize = 3;
      let currentY = pageHeight - marginY - fontSize;

      const doc = await PDFDocument.create();
      const font = await doc.embedFont(StandardFonts.Helvetica);
      const page = doc.addPage([pageWidth, pageHeight]);

      if (companyName) {
        page.drawText(companyName, {
          x: marginX,
          y: currentY,
          size: fontSize,
          font,
        });
        currentY -= ySpace;
      }

      if (officeName != null) {
        page.drawText(officeName, {
          x: marginX,
          y: currentY,
          size: fontSize,
          font,
        });
        currentY -= ySpace;
      }

      if (recipientName != null) {
        page.drawText(recipientName, {
          x: marginX,
          y: currentY,
          size: fontSize,
          font,
        });
        currentY -= ySpace;
      }

      if (employeeName != null) {
        page.drawText(`c/o: ${employeeName}`, {
          x: marginX,
          y: currentY,
          size: fontSize,
          font,
        });
        currentY -= ySpace;
      }

      page.drawText(
        `${format(getDateWithTimezoneFixed(eventDate) as Date, 'yyyy-MM-dd')} - ${getGiftEventType(gift)}`,
        {
          x: marginX,
          y: currentY,
          size: fontSize,
          font,
        },
      );
      currentY -= ySpace;

      return doc;
    }),
  );

  const pdfDoc = await PDFDocument.create();
  await Promise.all(
    docs.map(async (doc) => {
      const pages = doc.getPages();
      const copiedPages = await pdfDoc.copyPages(
        doc,
        pages.map((_, index) => index),
      );
      for (const page of copiedPages) {
        pdfDoc.addPage(page);
      }
    }),
  );

  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: 'application/pdf' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = 'print-labels.pdf';
  link.click();
  link.remove();
};
