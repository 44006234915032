import Joi from 'joi';
import { TDependantType } from '../../generated/graphql';
import { AWSDateSchema } from '../shared/utils';
import { clearableField } from '../shared/clearableField';

export const schema = () => {
  const dependantSchema = Joi.object()
    .unknown(true)
    .or('firstName', 'preferredFirstName')
    .keys({
      type: Joi.string()
        .trim()
        .valid(...Object.values(TDependantType))
        .required(),
      firstName: clearableField(Joi.string().trim()),
      preferredFirstName: clearableField(Joi.string().trim()),
      lastName: Joi.when('type', {
        is: TDependantType.Pet,
        then: Joi.forbidden(),
        otherwise: clearableField(Joi.string().trim()),
      }),
      petType: Joi.when('type', {
        is: TDependantType.Pet,
        then: Joi.string().trim().lowercase().required(),
        otherwise: Joi.forbidden(),
      }),
      birthDate: clearableField(AWSDateSchema),
      foodPreferences: clearableField(Joi.string().trim()),
    });

  return dependantSchema;
};
