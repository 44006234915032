import {
  ModalForm,
  TControlledFormComponentProps,
  TControlledFormProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import {
  TSetShipmentAsReadyToShipMutationInput,
  useSetShipmentAsReadyToShipMutation,
} from '../../../generated/graphql';

import styles from './SetAsReadyToShipModal.module.scss';
import { TShipmentType } from '../../../entities/shipments/queries';

export type TSetAsReadyToShipModalProps = {
  shipments: TShipmentType[];
  onCancel: () => void;
  onSuccess: () => void;
};

type TTrailingTextProps = {
  text: string;
};

const TrailingText = (props: TTrailingTextProps) => {
  const { text } = props;

  return <span className={styles.trailingText}>{text}</span>;
};

export const SetAsReadyToShipModal = (props: TSetAsReadyToShipModalProps) => {
  const { shipments, onCancel, onSuccess } = props;

  const formFields: TControlledFormComponentProps[] = useMemo(() => {
    return [
      {
        type: 'textField',
        label: 'Package Length',
        name: 'packageInfo.length',
        inputOptions: {
          autoComplete: 'off',
          className: styles.numberInput,
          multiline: false,
          trailingIcon: <TrailingText text='in' />,
          type: 'number',
        },
      },
      {
        type: 'textField',
        label: 'Package Width',
        name: 'packageInfo.width',
        inputOptions: {
          autoComplete: 'off',
          className: styles.numberInput,
          multiline: false,
          trailingIcon: <TrailingText text='in' />,
          type: 'number',
        },
      },
      {
        type: 'textField',
        label: 'Package Height',
        name: 'packageInfo.height',
        inputOptions: {
          autoComplete: 'off',
          className: styles.numberInput,
          multiline: false,
          trailingIcon: <TrailingText text='in' />,
          type: 'number',
        },
      },
      {
        type: 'textField',
        label: 'Package Weight',
        name: 'packageInfo.weight',
        inputOptions: {
          autoComplete: 'off',
          className: styles.numberInput,
          multiline: false,
          trailingIcon: <TrailingText text='lb' />,
          type: 'number',
        },
      },
    ];
  }, []);

  const schema = useMemo(() => {
    return Joi.object()
      .unknown(false)
      .keys({
        packageInfo: Joi.object().unknown(false).keys({
          length: Joi.number().required(),
          width: Joi.number().required(),
          height: Joi.number().required(),
          weight: Joi.number().required(),
        }),
      });
  }, []);

  const headline = useMemo(() => {
    return `Moving ${shipments.length} Shipment${shipments.length === 1 ? '' : 's'} to Ready to Ship`;
  }, [shipments.length]);

  const supportingText = useMemo(() => {
    return `Set the dimensions for the ${shipments.length} moving package${shipments.length === 1 ? '' : 's'}.`;
  }, [shipments.length]);

  const [setShipmentAsReadyToShip] = useSetShipmentAsReadyToShipMutation();

  const onSubmit = useCallback<TControlledFormProps<TSetShipmentAsReadyToShipMutationInput>['onValidSubmit']>(
    (data) => {
      return Promise.all(
        shipments.map((shipment) => {
          return setShipmentAsReadyToShip({
            variables: {
              id: shipment.id,
              companyId: shipment.companyId,
              version: shipment._version,
              input: data,
            },
          });
        }),
      )
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error setting the shipments as ready to ship.');
        });
    },
    [onSuccess, setShipmentAsReadyToShip, shipments],
  );

  return (
    <ModalForm
      closeModal={onCancel}
      confirmLabel='Move to Ready to Ship'
      contentClassName={styles.setAsReadyToShipModal}
      controlledFormProps={{
        className: styles.form,
        fields: formFields as TControlledFormComponentProps<TSetShipmentAsReadyToShipMutationInput>[],
        formProps: {
          resolver: joiResolver(schema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
      }}
      headline={headline}
      onCancelClick={onCancel}
      size='large'
      supportingText={supportingText}
    />
  );
};
