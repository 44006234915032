import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  ErrorPage,
  InfoCardInner,
  LoadingPage,
  TMenuItemProps,
  readableDate,
  Tooltip,
  Icon,
} from '@chocolate-soup-inc/cs-frontend-components';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { getReadableGiftType, getRemovedInfoFromRecipientData, isDonationGift } from '../../../entities/gifts/shared';
import { ShipmentModal } from './ShipmentModal';
import { isDependant } from '../../../entities/recipients/shared';
import { getReadableRelationshipType } from '../../../entities/dependants/shared';
import { RemovingGiftFromShipmetModal } from './RemovingGiftFromShipmetModal';
import { SHIPMENTS_PATH } from '../../../routes/paths';
import { ShipmentFieldsFragmentDoc, TShipmentStatuses } from '../../../generated/graphql';
import { cache } from '../../../config/apollo/cache';
import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useFragmentOrFetchShipment } from '../../../entities/shipments/queries';
import { TGiftType, useQueryAllShipmentGifts } from '../../../entities/gifts/queries';
import { useFilter } from '../../../contexts/filters';
import style from './ShipmentModalGifts.module.scss';
import clsx from 'clsx';

export const ShipmentModalGifts = () => {
  const { filtersModalMode } = useFilter();
  useEffect(() => {
    filtersModalMode();
  }, []); // eslint-disable-line

  const { companyId, shipmentId } = useParams();
  const navigate = useNavigate();

  const modalRef = useRef<HTMLDivElement>(null);

  const [removingGiftFromShipment, setRemovingGiftFromShipment] = useState<TGiftType>();

  const { data: shipment } = useFragmentOrFetchShipment({
    id: shipmentId as string,
    companyId: companyId as string,
  });

  const {
    data: gifts,
    error,
    loading,
  } = useQueryAllShipmentGifts({
    shipmentId: shipmentId as string,
    companyId: companyId as string,
  });

  const optionsMenu = useCallback(
    (gift: TGiftType) => {
      if (
        shipment != null &&
        [TShipmentStatuses.Packaging || TShipmentStatuses.ReadyToShip].includes(shipment.status)
      ) {
        return [
          {
            closeOnClick: true,
            label: 'Remove gift from shipment',
            leadingIcon: 'group_off',
            onClick: () => {
              setRemovingGiftFromShipment(gift);
            },
            type: 'text',
          },
        ] as TMenuItemProps[];
      }
    },
    [shipment],
  );

  const finalGifts = useMemo(() => {
    return gifts.filter((g) => g.shipmentId === shipmentId);
  }, [gifts, shipmentId]);

  const closeModal = useCallback(() => {
    setRemovingGiftFromShipment(undefined);
  }, []);

  const onMoveToDoneCancel = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const onMoveToDoneConfirm = useCallback(() => {
    if (finalGifts.length === 1 && shipment != null) {
      // THIS MEANS WE CHANGED THE ONLY GIFT (SHOULD NOT BE POSSIBLE)
      cache.writeFragment({
        data: {
          ...shipment,
          _deleted: true,
        },
        id: cache.identify(shipment),
        fragment: ShipmentFieldsFragmentDoc,
        fragmentName: getFragmentName(ShipmentFieldsFragmentDoc),
      });
      navigate(generatePath(SHIPMENTS_PATH));
    }
    closeModal();
  }, [closeModal, finalGifts.length, navigate, shipment]);

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {removingGiftFromShipment != null && (
        <div ref={modalRef}>
          <RemovingGiftFromShipmetModal
            addressId={removingGiftFromShipment.addressId as string}
            companyId={removingGiftFromShipment.companyId}
            id={removingGiftFromShipment.id}
            shipmentId={removingGiftFromShipment.shipmentId || undefined}
            giftsLength={finalGifts.length}
            closeModal={closeModal}
            onCancel={onMoveToDoneCancel}
            onSuccess={onMoveToDoneConfirm}
          />
        </div>
      )}
      <ShipmentModal headline='Shipment Gifts'>
        {loading && <LoadingPage />}
        {!loading &&
          finalGifts.map((gift, index) => (
            <InfoCardInner<TGiftType>
              className={gift?.recipientData ? style.deleted : ''}
              key={gift.id}
              data={gift}
              sections={[
                {
                  title: `Gift #${index + 1}`,
                  columns: [
                    {
                      label: 'Recipient Name',
                      render: () => {
                        return (
                          <div className={clsx(style.badgesContainer)}>
                            <span>{gift?.recipient?.fullName ?? getRemovedInfoFromRecipientData(gift)}</span>
                            {gift.alerts?.map((a, i) => (
                              <Tooltip key={i} message={a?.message as string}>
                                <Icon className={clsx(style.warningIcon)} icon='warning' />
                              </Tooltip>
                            ))}
                          </div>
                        );
                      },
                    },
                    {
                      label: 'Relationship',
                      render: () => {
                        const recipient = gift?.recipient;
                        if (recipient && isDependant(recipient)) {
                          return `${getReadableRelationshipType(recipient)} of ${recipient.employee?.fullName}`;
                        }
                      },
                    },
                    {
                      label: 'Event Type',
                      render: () => {
                        return getReadableGiftType(gift);
                      },
                    },
                    {
                      label: 'Event Date',
                      render: () => readableDate(gift?.eventDate),
                    },
                    {
                      label: 'Donation',
                      render: () => {
                        const isDonation = isDonationGift({ gift });
                        return isDonation ? 'yes' : 'no';
                      },
                    },
                  ],
                  optionsMenu: optionsMenu(gift),
                },
              ]}
            />
          ))}
      </ShipmentModal>
    </>
  );
};
