import { Link } from '@chocolate-soup-inc/cs-frontend-components';
import { TShipmentFieldsFragment } from '../../generated/graphql';
import styles from './StatusCell.module.scss';

export const getShipmentFinalStatus = (shipment?: TShipmentFieldsFragment) => {
  const { status } = shipment || {};

  switch (status) {
    case 'packaging':
    case 'readyToShip':
    case 'printingLabel':
    case undefined:
      return 'Assembling';
    case 'labelPrinted':
    case 'shipped':
      return 'In Transit';
    case 'delivered':
      return 'Delivered';
    case 'readyForPickup':
      return 'Available for Pickup';
    case 'returned':
      return 'Returned';
    default:
      return status;
  }
};

export const getShipmentFinalColor = (shipment?: TShipmentFieldsFragment) => {
  const { status } = shipment || {};

  switch (status) {
    case 'packaging':
    case 'readyToShip':
    case 'printingLabel':
    case undefined:
      return '#92979D';
    case 'labelPrinted':
    case 'shipped':
      return '#3E66FB';
    case 'delivered':
      return '#66CA65';
    case 'readyForPickup':
      return '#F3BB1C';
    case 'returned':
      return '#F03738';
  }
};

export const getTrackingLink = (shipment: TShipmentFieldsFragment) => {
  const { trackingNumber, label } = shipment;
  const { carrier = undefined } = label || {};

  switch (carrier) {
    case 'canada_post':
      return `https://www.canadapost-postescanada.ca/track-reperage/en#/search?searchFor=${trackingNumber}`;
    case 'purolator_ca':
      return `https://www.purolator.com/en/shipping/tracker?pin=${trackingNumber}`;
    case 'dhl_express_canada':
      return `https://www.dhl.com/br-en/home/tracking/tracking-express.html?submit=1&tracking-id=${trackingNumber}`;
    case 'ups':
      return `https://www.ups.com/track?track=yes&trackNums=${trackingNumber}&loc=en_US&requester=ST/trackdetails`;
    default:
      break;
  }
};

export const TrackingLink = (shipment: TShipmentFieldsFragment) => {
  if (shipment.trackingNumber) {
    return (
      <Link
        href={getTrackingLink(shipment)}
        label={shipment.trackingNumber}
        icon={true}
        onClick={(e: React.MouseEvent) => {
          e.stopPropagation();
          e.preventDefault();

          window.open(getTrackingLink(shipment));
        }}
      />
    );
  }

  return <span>-</span>;
};

export const StatusCell = (shipment?: TShipmentFieldsFragment) => {
  const text = getShipmentFinalStatus(shipment);
  const color = getShipmentFinalColor(shipment);

  return (
    <div className={styles.statusContainer}>
      <span className={styles.statusIndicator} style={{ backgroundColor: color }} />
      <span className={styles.statusText}>{text}</span>
    </div>
  );
};
