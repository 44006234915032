import { Icon, InfoCardInner, readableDate, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { TShipmentType } from '../../../entities/shipments/queries';
import { TrackingLink } from '../../../entities/shipments/utils';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import styles from '../ShipmentsTracking/ShipmentsTracking.module.scss';
import { TGiftType } from '../../../entities/gifts/queries';
import { isDependant } from '../../../entities/recipients/shared';
import { getReadableRelationshipType } from '../../../entities/dependants/shared';
import { getReadableGiftType, isDonationGift } from '../../../entities/gifts/shared';

type TrackingDetailsProps = {
  shipment: any;
  recipientGifts: any[] | null | undefined;
};
const CardRecipientCell = (props: { shipment?: TShipmentType }) => {
  const { addressTypeIcon, recipientName, readableAddressType } = useShipmentAddressInfo(props.shipment);
  return (
    <div className={styles.recipientCell}>
      {addressTypeIcon && readableAddressType && (
        <Tooltip autoPlacement={false} message={readableAddressType} placement='bottom'>
          <Icon filled={true} icon={addressTypeIcon} />
        </Tooltip>
      )}
      {recipientName}
    </div>
  );
};
export const TrackingDetailsModal = (props: TrackingDetailsProps) => {
  const { shipment, recipientGifts } = props;

  //if (shipment === null) return;

  return (
    <>
      {shipment != null && (
        <InfoCardInner<TShipmentType>
          data={shipment}
          sections={[
            {
              title: 'Shipment',
              columns: [
                {
                  label: 'Delivery Date',
                  render: () => readableDate(shipment.shippingDate ?? undefined),
                },
                {
                  label: 'Tracking Number',
                  render: () => (shipment ? TrackingLink(shipment) : null),
                },
                {
                  label: 'Company',
                  render: () => shipment?.company?.name ?? '',
                },
                {
                  label: 'Recipient',
                  render: () => <CardRecipientCell shipment={shipment} />,
                },
              ],
            },
          ]}
        />
      )}
      {recipientGifts &&
        recipientGifts.map((gift, index) => {
          return (
            <InfoCardInner<TGiftType>
              key={gift.id}
              data={gift}
              sections={[
                {
                  title: `Gift #${recipientGifts.length - index}`,
                  columns: [
                    {
                      label: 'Shipment Order ID',
                      render: () => gift?.shipmentId,
                    },
                    {
                      label: 'Recipient Name',
                      render: () => gift?.recipient?.fullName,
                    },
                    {
                      label: 'Relationship',
                      render: () => {
                        const recipient = gift?.recipient;
                        if (recipient && isDependant(recipient)) {
                          return `${getReadableRelationshipType(recipient)} of ${recipient.employee?.fullName}`;
                        }
                      },
                    },
                    {
                      label: 'Event Type',
                      render: () => {
                        return getReadableGiftType(gift);
                      },
                    },
                    {
                      label: 'Event Date',
                      render: () => readableDate(gift?.eventDate),
                    },
                    {
                      label: 'Donation',
                      render: () => {
                        const isDonation = isDonationGift({ gift });
                        return isDonation ? 'yes' : 'no';
                      },
                    },
                  ],
                },
              ]}
            />
          );
        })}
    </>
  );
};
