import Joi from 'joi';
import { AWSDateSchema } from '../shared/utils';
import _ from 'lodash';
import { clearableField } from '../shared/clearableField';
import { TEventType } from './queries';

export const getEventSchema = () => {
  const eventSchema = Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      companyId: Joi.string().trim().required(),
      employeeId: Joi.string().trim().required(),
      recipientId: Joi.string().trim().required(),
      addressId: clearableField(Joi.string().trim()),
      referenceEventId: clearableField(Joi.string().trim()),
      giftId: clearableField(Joi.string().trim()),
      eventDate: AWSDateSchema.empty(Joi.valid(null, '')).required(),
      type: Joi.string().trim().required(),
      customType: clearableField(Joi.string().trim()),
      age: Joi.number().required(),
      //giftCreatedAt: Joi.forbidden(),
      skippedAt: clearableField(AWSDateSchema.empty(Joi.valid(null, '')).required()),
      deliveredAt: clearableField(AWSDateSchema.empty(Joi.valid(null, '')).required()),
      groupedToEventId: clearableField(Joi.string().trim()),
      //hasGift: Joi.number().forbidden().empty(Joi.any()),
      //isSkipped: Joi.number().forbidden().empty(Joi.any()),
      //isSkippedOrHasGift: Joi.number().forbidden().empty(Joi.any()),
      isPaused: Joi.boolean().cast('number'),
      wasPaused: Joi.boolean().cast('number'),
    });

  return eventSchema;
};

export const defaultValues = {
  age: 0,
  isPaused: false,
  wasPaused: false,
};

export const getEventDefaultValues = (event?: TEventType) => {
  return {
    ...defaultValues,
    ..._.omit(event || {}, ['id', '__typename', '_version', '_deleted']),
  };
};
