import Joi from 'joi';
import { TGetCompanyQuery, TUserType } from '../../generated/graphql';
import _ from 'lodash';

type TCompany = Exclude<TGetCompanyQuery['getCompany'], undefined | null>;

type TGetUserSchemaProps = {
  company?: TCompany;
};

export const getUserSchema = (props: TGetUserSchemaProps) => {
  const { company: { emailDomains: propEmailDomains } = {} } = props;

  let emailDomains = _.cloneDeep(propEmailDomains);
  if (emailDomains == null) emailDomains = [];
  emailDomains.push('chocolatesoup.ca');

  const schema = Joi.object()
    .unknown(false)
    .keys({
      name: Joi.string().trim().empty(Joi.valid('', null)).required(),
      email: Joi.string()
        .trim()
        .lowercase()
        .email({
          tlds: {
            allow: false,
          },
        })
        .empty(Joi.valid('', null))
        .required()
        .custom((value) => {
          if (!emailDomains?.includes(value.split('@')[1])) {
            throw new Error(`Email from invalid domain. It needs to be one of [${emailDomains?.join(', ')}]`);
          }

          return value;
        }),
      title: Joi.string().trim().empty(Joi.valid('', null)),
      type: Joi.string()
        .valid(...Object.values(TUserType))
        .required(),
    });

  return schema;
};
