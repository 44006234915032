import {
  Card,
  Checkbox,
  ConfirmationModal,
  ErrorPage,
  LoadingPage,
  Modal,
  Select,
  TModalProps,
  TSelectBaseProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import styles from './RemovingGiftFromShipmetModal.module.scss';
import { TShipmentStatuses, useDeleteGiftMutation, useUpdateGiftMutation } from '../../../generated/graphql';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import _ from 'lodash';
import { useFragmentOrFetchGift } from '../../../entities/gifts/queries';
import { TShipmentType, useQueryAllShipmentFromAddressWithoutALabel } from '../../../entities/shipments/queries';
import { useCreateShipmentWithGifts } from '../../../entities/gifts/mutations';

export type TPossibleActions = 'create' | 'add' | 'remove';

export type TConfirmationProps = Pick<
  TModalProps,
  'confirmLabel' | 'icon' | 'headline' | 'onConfirmClick' | 'open' | 'supportingText'
>;

type TRemovingGiftFromShipmetModalProps = {
  addressId: string;
  companyId: string;
  id: string;
  shipmentId?: string;
  giftsLength: number;
  closeModal: () => void;
  onCancel?: () => void;
  onSuccess: (actionType: TPossibleActions) => void;
};

export const RemovingGiftFromShipmetModal = (props: TRemovingGiftFromShipmetModalProps) => {
  const { addressId, id, companyId, shipmentId, giftsLength, closeModal, onCancel = closeModal, onSuccess } = props;

  const [selectedAction, setSelectedActions] = useState<TPossibleActions>();
  const [selectedShipment, setSelectedShipment] = useState<TShipmentType>();
  const [confirmationProps, setConfirmationPropsState] = useState<TConfirmationProps>({});

  const resetConfirmationModal = useCallback(() => {
    setConfirmationPropsState({});
  }, []);

  useEffect(() => {
    if (selectedAction !== 'add') setSelectedShipment(undefined);
  }, [selectedAction]);

  const { data: gift } = useFragmentOrFetchGift({
    id,
    companyId,
  });

  const {
    data: shipments,
    error: shipmentsError,
    loading: shipmentsLoading,
  } = useQueryAllShipmentFromAddressWithoutALabel({
    addressId,
  });

  const possibleShipments = useMemo(() => {
    return shipments.filter((s) => {
      if (s.companyId !== companyId) return false;
      if (shipmentId != null && s.id === shipmentId) return false;

      return true;
    });
  }, [companyId, shipmentId, shipments]);

  const onCancelClick = useCallback(() => {
    if (onCancel) onCancel();
  }, [onCancel]);

  const [updateGift, { loading: updateGiftLoading }] = useUpdateGiftMutation();
  const [createShipmentWithGifts, { loading: createShipmentLoading }] = useCreateShipmentWithGifts();
  const [deleteGift, { loading: deleteGiftLoading }] = useDeleteGiftMutation();

  const actionLoading = useMemo(() => {
    return updateGiftLoading || createShipmentLoading || deleteGiftLoading;
  }, [createShipmentLoading, deleteGiftLoading, updateGiftLoading]);

  const onActualAction = useCallback(() => {
    if (selectedAction === 'create') {
      if (gift != null) {
        createShipmentWithGifts({
          variables: {
            input: {
              companyId: gift.companyId,
              addressId: gift.addressId as string,
              giftsIds: [gift.id],
              status: TShipmentStatuses.Packaging,
              shippingDate: gift.shippingDate.toISOString().split('T')[0],
            },
          },
        })
          .then(() => {
            setSelectedShipment(undefined);
            toast.success('Gift added to new shipment.');
            if (onSuccess) onSuccess(selectedAction);
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error creating a new shipment for this gift.');
          })
          .then(() => {
            closeModal();
          });
      }
    } else if (selectedAction === 'add') {
      if (gift != null && selectedShipment != null) {
        updateGift({
          variables: {
            id: gift.id,
            companyId: gift.companyId,
            version: gift._version,
            input: {
              shipmentId: selectedShipment.id,
            },
          },
        })
          .then(() => {
            setSelectedShipment(undefined);
            toast.success('Gift shipment updated.');
            if (onSuccess) onSuccess(selectedAction);
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error changing the gift shipment.');
          })
          .then(() => {
            closeModal();
          });
      }
    } else if (selectedAction === 'remove') {
      if (gift) {
        deleteGift({
          variables: {
            id: gift.id,
            companyId: gift.companyId,
            version: gift._version,
          },
        })
          .then(() => {
            setSelectedShipment(undefined);
            toast.success('Gift deleted.');
            if (onSuccess) onSuccess(selectedAction);
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error deleting the gift.');
          })
          .then(() => {
            closeModal();
          });
      }
    }
  }, [closeModal, createShipmentWithGifts, deleteGift, gift, onSuccess, selectedAction, selectedShipment, updateGift]);

  const onConfirmClick = useCallback(() => {
    if (selectedAction != null) {
      let headline: string;
      let supportingText: string;
      let confirmLabel: string;

      if (selectedAction === 'add') {
        headline = 'Update gift shipment?';
        supportingText = 'This gift will be removed from this shipment and added to the selected shipment.';
        confirmLabel = 'Update gift shipment';
      } else if (selectedAction === 'create') {
        headline = 'Add gift to new shipment?';
        supportingText =
          'A new shipment will be created containing this gift, which will be removed from this existing shipment.';
        confirmLabel = 'Create shipment';
      } else if (selectedAction === 'remove') {
        headline = 'Delete gift?';
        supportingText = 'This gift will be removed from the current shipment and deleted.';
        confirmLabel = 'Delete gift';
      } else {
        return;
      }

      if (giftsLength === 1) {
        supportingText = `${supportingText} This shipment will also be deleted as it will have no more gifts.`;
      }

      setConfirmationPropsState({
        headline,
        supportingText,
        confirmLabel,
        onConfirmClick: onActualAction,
        open: true,
      });
    }
  }, [giftsLength, onActualAction, selectedAction]);

  const possibleActions: TSelectBaseProps['options'] = useMemo(() => {
    const actions: TSelectBaseProps['options'] = [];

    if (giftsLength > 1) {
      actions.push({
        leadingIcon: 'library_add',
        label: 'Create new shipment',
        value: 'create',
      });
    }

    if (possibleShipments.length > 0) {
      actions.push({
        leadingIcon: 'group_add',
        label: 'Add to existing shipment',
        value: 'add',
      });
    }

    actions.push({
      leadingIcon: 'delete',
      label: 'Delete gift',
      value: 'remove',
    });

    return actions;
  }, [giftsLength, possibleShipments.length]);

  const modalText = useMemo(() => {
    let t: string | undefined;
    if (selectedAction === 'create') {
      t = 'A new shipment will be created for this gift.';
    } else if (selectedAction === 'remove') {
      t = 'This gift will be removed from the system.';
    } else if (selectedAction === 'add') {
      t = 'Select to which existing shipment do you want to add this gift to.';
    }

    if (t != null && giftsLength <= 1) {
      t = `${t} This shipment will also be deleted as it will have no more gifts.`;
    }

    return t;
  }, [giftsLength, selectedAction]);

  if (shipmentsError) {
    return (
      <Modal closeModal={onCancelClick}>
        <ErrorPage error={shipmentsError} />
      </Modal>
    );
  }

  if (shipmentsLoading) {
    return (
      <Modal closeModal={onCancelClick}>
        <LoadingPage />
      </Modal>
    );
  }

  return (
    <>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={resetConfirmationModal}
          confirmLoading={actionLoading}
          onCancelClick={resetConfirmationModal}
        />
      )}
      {_.isEmpty(confirmationProps) && (
        <Modal
          contentClassName={styles.modal}
          cancelLabel='Cancel'
          closeModal={onCancelClick}
          confirmDisabled={selectedAction == null || (selectedAction === 'add' && selectedShipment == null)}
          confirmLabel='Remove'
          confirmLoading={actionLoading}
          headline='Remove Gift From Shipment'
          onCancelClick={onCancelClick}
          onConfirmClick={onConfirmClick}
          showCancelButton={true}
          showConfirmButton={true}
          size='small'
          supportingText='Set what you want to do with the gift.'
        >
          <Select
            className={styles.actionSelect}
            disabled={actionLoading}
            label='Action'
            multiple={false}
            name='action'
            options={possibleActions}
            onChange={(v: any) => {
              setSelectedActions(v as TPossibleActions);
            }}
            value={selectedAction}
          />
          {modalText && <p className={styles.text}>{modalText}</p>}
          {selectedAction === 'add' && (
            <>
              {possibleShipments.map((s, index) => {
                const selected = s.id === selectedShipment?.id;

                return (
                  <button
                    className={styles.shipmentCardContainer}
                    disabled={actionLoading}
                    key={s.id}
                    onClick={() => setSelectedShipment(s)}
                  >
                    <Card
                      className={styles.cardCheckbox}
                      readOnly={actionLoading}
                      variant={selected ? 'filled' : 'outlined'}
                    >
                      <div className={styles.cardContent}>
                        <Checkbox name='shipment' value={selected} />
                        <p className={styles.cardTitle}>Shipment #{index + 1}</p>
                        <p className={styles.cardAddress}>{`${s.address?.address1}${
                          s.address?.address2 != null && s.address?.address2 !== '' ? `, ${s.address.address2}` : ''
                        }, ${s.address?.city}, ${s.address?.state}, ${s.address?.country}`}</p>
                      </div>
                    </Card>
                  </button>
                );
              })}
            </>
          )}
        </Modal>
      )}
    </>
  );
};
