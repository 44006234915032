import { useMemo } from 'react';
import {
  AddressFieldsFragmentDoc,
  TGetAddressByIdQuery,
  TGetAddressByIdQueryVariables,
  TListAllAddressesQuery,
  TListAllAddressesQueryVariables,
  useGetAddressByIdLazyQuery,
  useListAllAddressesLazyQuery,
} from '../../generated/graphql';
import { useMap } from '../shared/useMap';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';

// LISTS

export const useQueryAllAddresses = () => {
  const { data, error, loading } = useQueryAll<TListAllAddressesQuery, TListAllAddressesQueryVariables>({
    useQuery: useListAllAddressesLazyQuery,
    variables: {},
  });

  const addresses = useMemo(() => {
    return _.compact(data?.listAddresses?.items);
  }, [data?.listAddresses?.items]);

  return {
    data: addresses,
    error,
    loading,
  };
};

export const useFragmentOrFetchAddress = (variables: TGetAddressByIdQueryVariables) => {
  const { data, loading, error } = useFragmentOrFetch<
    TListAllAddressesQuery,
    TGetAddressByIdQuery,
    TGetAddressByIdQueryVariables
  >({
    fragmentDoc: AddressFieldsFragmentDoc,
    useLazyQuery: useGetAddressByIdLazyQuery,
    variables,
    __typename: 'Address',
  });

  return { data, error, loading };
};

export const useQueryAddressById = (id: string) => {
  const { data, error, loading } = useQueryAll<TGetAddressByIdQuery, TGetAddressByIdQueryVariables>({
    useQuery: useGetAddressByIdLazyQuery,
    variables: { id },
  });

  const address = useMemo(() => {
    return data?.getAddressById;
  }, [data]);

  return {
    data: address,
    error,
    loading,
  };
};

export type TAddressType = ReturnType<typeof useQueryAllAddresses>['data'][number];

export const useAllAddressesMap = () => {
  const { data, error, loading } = useQueryAllAddresses();

  const addressesMap = useMap<(typeof data)[number]>(data);

  return {
    data: addressesMap,
    error,
    loading: loading || addressesMap == null,
  };
};
