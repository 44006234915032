import _ from 'lodash';
import { isDependant } from '../../../entities/recipients/shared';
import { TShipmentType } from '../../../entities/shipments/queries';
import { TDependantType, TGiftTypes, TWorkAnniversaryGiftVariations } from '../../../generated/graphql';

const NEW_HIRE_KIT = [
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
];

const SMALL_PLAN_WORK_ANNIVERSARY = [
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 2,
    hsCode: '3401.00',
    quantity: 1,
    description: 'Dish soap bar kit',
    country: 'CAN',
  },
  {
    value: 1,
    hsCode: '1806.90',
    quantity: 1,
    description: 'candy floss',
    country: 'CAN',
  },
];

const LARGE_PLAN_WORK_ANNIVERSARY = [
  {
    value: 3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego set',
    country: 'DNK',
  },
  {
    value: 2,
    hsCode: '3401.00',
    quantity: 1,
    description: 'soap dish kit',
    country: 'CAN',
  },
  {
    value: 1,
    hsCode: '1806.90',
    quantity: 1,
    description: 'candy floss',
    country: 'CAN',
  },
  {
    value: 2,
    hsCode: '7013.10',
    quantity: 2,
    description: 'resuable glass jar ',
    country: 'CAN',
  },
  {
    value: 0.2,
    hsCode: '3402.90',
    quantity: 2,
    description: 'cleaning tablets',
    country: 'CAN',
  },
];

const BRICK_ONLY_WORK_ANNIVERSARY = [
  {
    value: 0.3,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Lego Brick',
    country: 'DNK',
  },
];

const EMPLOYEE_BIRTHDAY = [
  {
    value: 1,
    hsCode: '9503.00',
    quantity: 1,
    description: 'Plush stuff toy',
    country: 'CHN',
  },
  {
    value: 2,
    hsCode: '1901.20',
    quantity: 1,
    description: 'pancake mix',
    country: 'CAN',
  },
  {
    value: 0.5,
    hsCode: '1702.20',
    quantity: 1,
    description: 'maple syrup',
    country: 'CAN',
  },
  {
    value: 0.2,
    hsCode: '4016.99',
    quantity: 1,
    description: 'Spatula',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '0712.90',
    quantity: 1,
    description: 'Dehydrated potatoes',
    country: 'CAN',
  },
  {
    value: 0.5,
    hsCode: '2007.10',
    quantity: 1,
    description: 'Mini jar of Jam',
    country: 'CAN',
  },
  {
    value: 0.5,
    hsCode: '0901.11',
    quantity: 1,
    description: 'coffee',
    country: 'CAN',
  },
];

const SPOUSE_BIRTHDAY = [
  {
    value: 3,
    hsCode: '3924.90',
    quantity: 1,
    description: 'plastic bird feeder',
    country: 'CHN',
  },
  {
    value: 0.5,
    hsCode: '2309.90',
    quantity: 1,
    description: 'bird seed',
    country: 'CAN',
  },
  {
    value: 1,
    hsCode: '1806.90',
    quantity: 1,
    description: 'candy gummies',
    country: 'CAN',
  },
];

const customsMapping = {
  NEW_HIRE_KIT,
  SMALL_PLAN_WORK_ANNIVERSARY,
  LARGE_PLAN_WORK_ANNIVERSARY,
  BRICK_ONLY_WORK_ANNIVERSARY,
  EMPLOYEE_BIRTHDAY,
  SPOUSE_BIRTHDAY,
};

export const getDefaultCustoms = (shipments: TShipmentType[]) => {
  let giftTypes: (keyof typeof customsMapping)[] = [];
  for (const shipment of shipments) {
    const company = shipment.company;
    for (const gift of shipment.gifts || []) {
      if (gift.type === TGiftTypes.NewHire) {
        giftTypes.push('NEW_HIRE_KIT');
      } else if (gift.type === TGiftTypes.WorkAnniversary) {
        if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.BrickOnly) {
          giftTypes.push('BRICK_ONLY_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Deluxe) {
          giftTypes.push('LARGE_PLAN_WORK_ANNIVERSARY');
        } else if (company?.workAnniversaryGiftVariation === TWorkAnniversaryGiftVariations.Mini) {
          giftTypes.push('SMALL_PLAN_WORK_ANNIVERSARY');
        }
      } else if (gift.type === TGiftTypes.Birthday) {
        const recipient = gift.recipient;

        if (recipient) {
          if (isDependant(recipient)) {
            if (recipient.type === TDependantType.SignificantOther) {
              giftTypes.push('SPOUSE_BIRTHDAY');
            }
          } else {
            giftTypes.push('EMPLOYEE_BIRTHDAY');
          }
        }
      }
    }
  }

  giftTypes = _.uniq(giftTypes);

  if (giftTypes.length === 1) {
    return customsMapping[giftTypes[0]];
  }

  return undefined;
};
