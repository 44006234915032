import { ApolloError } from '@apollo/client';
import {
  CommonButton,
  ConfirmationModal,
  ErrorPage,
  LoadingPage,
  TModalProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { ForwardedRef, MouseEventHandler, forwardRef, useMemo } from 'react';
import { useEffect } from 'react';
import { ReactNode, useCallback, useState } from 'react';
import { useLayoutContext } from '../../pages/shared/Layout';

import styles from './TablePage.module.scss';
import clsx from 'clsx';

export type TConfirmationProps = Pick<
  TModalProps,
  'confirmLabel' | 'icon' | 'headline' | 'onConfirmClick' | 'open' | 'supportingText'
>;

export type TTablePageRef = {
  resetConfirmationModal: () => void;
  setConfirmationProps: (v?: TConfirmationProps) => void;
};

type TTablePageProps = {
  actionsLoading?: boolean;
  children?: ReactNode;
  dataLoading?: boolean;
  error?: ApolloError;
  forwardedRef: ForwardedRef<TTablePageRef>;
  title?: string;
  titleButtonLabel?: string;
  titleButtonOnClick?: MouseEventHandler<HTMLButtonElement>;
};

export const TablePageInner = (props: TTablePageProps) => {
  const { actionsLoading, children, dataLoading, error, forwardedRef, title, titleButtonLabel, titleButtonOnClick } =
    props;

  const { className, fullGridClassName, titleClassName } = useLayoutContext();

  const [confirmationProps, setConfirmationPropsState] = useState<TConfirmationProps>({});

  const resetConfirmationModal = useCallback(() => {
    setConfirmationPropsState({});
  }, []);

  const setConfirmationProps = useCallback((newProps?: TConfirmationProps) => {
    if (newProps == null) {
      setConfirmationPropsState({});
    } else {
      setConfirmationPropsState(_.cloneDeep(newProps));
    }
  }, []);

  useEffect(() => {
    const v: TTablePageRef = {
      resetConfirmationModal,
      setConfirmationProps,
    };

    if (typeof forwardedRef === 'function') {
      forwardedRef(v);
    } else if (forwardedRef != null) {
      forwardedRef.current = v;
    }
  }, [forwardedRef, resetConfirmationModal, setConfirmationProps]);

  const showTitleButton = useMemo(() => {
    return titleButtonLabel != null && titleButtonOnClick != null;
  }, [titleButtonLabel, titleButtonOnClick]);

  if (error) return <ErrorPage error={error} />;
  if (dataLoading) return <LoadingPage />;

  return (
    <div className={fullGridClassName}>
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={resetConfirmationModal}
          confirmLoading={actionsLoading}
          onCancelClick={resetConfirmationModal}
        />
      )}
      <div className={clsx(className, styles.contentContainer)}>
        {(title || showTitleButton) && (
          <div className={styles.header}>
            {title && <h1 className={clsx(titleClassName, styles.title)}>{title}</h1>}
            {showTitleButton && (
              <CommonButton
                className={styles.headerButton}
                label={titleButtonLabel || ''}
                onClick={titleButtonOnClick}
                variant='filled'
              />
            )}
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

TablePageInner.displayName = 'TablePageInner';

export const TablePage = forwardRef<TTablePageRef, Omit<TTablePageProps, 'forwardedRef'>>((props, ref) => {
  return <TablePageInner {...props} forwardedRef={ref} />;
});

TablePage.displayName = 'TablePage';
