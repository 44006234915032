import { TEventTypes } from '../../generated/graphql';

export const translateEventType = (type: TEventTypes) => {
  switch (type) {
    case TEventTypes.Birthday:
      return 'Birthday';
    case TEventTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TEventTypes.NewHire:
      return 'New Hire';
    case TEventTypes.BabyGift:
      return 'Baby Gift';
    case TEventTypes.Wedding:
      return 'Wedding/Engagement';
    case TEventTypes.ReplacementWorkAnniversary:
      return 'Replacement Work Anniversary';
    case TEventTypes.ReplacementEmployeeBirthday:
      return 'Replacement Employee Birthday';
    case TEventTypes.CarePackage:
      return 'Care Package';
    case TEventTypes.CustomBricks:
      return 'Custom Bricks';
    case TEventTypes.Other:
      return 'Other';
  }
};

export const ONE_OFF_EVENT_TYPES = {
  [TEventTypes.BabyGift]: 'Baby Gift',
  [TEventTypes.Wedding]: 'Wedding/Engagement',
  [TEventTypes.ReplacementWorkAnniversary]: 'Replacement Work Anniversary',
  [TEventTypes.ReplacementEmployeeBirthday]: 'Replacement Employee Birthday',
  [TEventTypes.CarePackage]: 'Care Package',
  [TEventTypes.CustomBricks]: 'Custom Bricks',
  [TEventTypes.Other]: 'Other',
};
