import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';

const redirectSignIn = `${window.location.origin}${process.env.REACT_APP_CALLBACK_PATH}`;
const redirectSignOut = `${window.location.origin}${process.env.REACT_APP_LOGOUT_PATH}`;

export const authOptions = {
  region: process.env.REACT_APP_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  authenticationFlowType: 'code',
  clientMetadata: {
    app: 'internal-portal',
  },

  // (optional) - Hosted UI configuration
  oauth: {
    domain: process.env.REACT_APP_AUTH_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    // clientId: `${process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID}:f3vk6nvcun052qukgnums3bjllmgrq43ea96mj0fb7g82dfro42`,
    redirectSignIn,
    redirectSignOut,
    responseType: 'code',
  },
};

if (!(process.env.REACT_APP_STAGE === 'production' || process.env.STAGE === 'production')) {
  console.debug({
    message: 'Cognito Auth Options',
    authOptions,
  });
}

Auth.configure(authOptions);
