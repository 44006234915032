import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import _ from 'lodash';
import { ErrorPage, Icon, LoadingPage, Select } from '@chocolate-soup-inc/cs-frontend-components';

import styles from '../EmployeesImport.module.scss';
import { useFragmentOrFetchCompany } from '../../../../entities/companies/queries';
import { useQueryAllCompanyOffices } from '../../../../entities/office/queries';

type TThirdStepProps = {
  companyId: string;
  file: File;
  mapping: Record<number, string | undefined>;
  officeMapping: Record<any, string>;
  setOfficeMapping: React.Dispatch<React.SetStateAction<Record<any, string>>>;
};

export const ThirdStep = (props: TThirdStepProps) => {
  const { companyId, file, mapping, officeMapping, setOfficeMapping } = props;

  const [officeValues, setOfficeValues] = useState<any[]>([]);

  const {
    // data: company,
    error: companyError,
    loading: companyLoading,
  } = useFragmentOrFetchCompany({
    id: companyId,
  });

  const {
    data: offices,
    error: officesError,
    loading: officesLoading,
  } = useQueryAllCompanyOffices({
    companyId,
  });

  const officeColumnIndex = useMemo(() => {
    if (mapping) {
      const [index] = Object.entries(mapping).find(([, value]) => value === 'officeId') || [];

      if (index != null) {
        return parseInt(index);
      }
    }
  }, [mapping]);

  useEffect(() => {
    if (file && officeColumnIndex) {
      file.arrayBuffer().then((data) => {
        const workbook = XLSX.read(data);
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const parsedData = XLSX.utils.sheet_to_json<any[]>(firstSheet, { header: 1 });

        const rows = parsedData.slice(1);
        const offices = _.uniq(rows.map((r) => r[officeColumnIndex]));

        setOfficeValues(offices);
      });
    }
  }, [file, officeColumnIndex]);

  if (companyError || officesError) {
    return <ErrorPage error={companyError || officesError} />;
  }

  if (companyLoading || officesLoading) {
    return <LoadingPage />;
  }

  if (offices == null || offices.length < 1) {
    return (
      <p className={styles.errorMessage}>
        No offices found for this company. Change the office column mapping or add offices to this company before
        importing the roster.
      </p>
    );
  }

  return (
    <div className={styles.thirdStep}>
      <h2 className={styles.subTitle}>Office Mappings</h2>
      <div>
        {officeValues
          .filter((value) => value != null && value !== '')
          .map((value, index) => {
            return (
              <div className={styles.officeMappingRow} key={index}>
                <span className={styles.officeMappingRowText}>{value}</span>
                <Icon icon='arrow_forward' />
                <Select
                  autoComplete='off'
                  includeEmptyOption={true}
                  name={`officeMapping[${index}]`}
                  multiple={false}
                  onChange={(v) => {
                    setOfficeMapping((currentMapping) => {
                      if (v == null) {
                        delete currentMapping[value];
                      } else {
                        currentMapping[value] = v as string;
                      }
                      return _.cloneDeep(currentMapping);
                    });
                  }}
                  options={offices.map((o) => ({
                    label: o.name,
                    value: o.id,
                  }))}
                  variant='outlined'
                  value={officeMapping[value]}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};
