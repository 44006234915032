import { TWorkAnniversaryGiftVariations } from '../../generated/graphql';
import { Icon } from '@chocolate-soup-inc/cs-frontend-components';
import styles from './shared.module.scss';

export const getReadableWorkAnniversaryVariation = (variation?: TWorkAnniversaryGiftVariations) => {
  switch (variation) {
    case TWorkAnniversaryGiftVariations.Mini:
      return 'Mini';
    case TWorkAnniversaryGiftVariations.Deluxe:
      return 'Deluxe';
    case TWorkAnniversaryGiftVariations.BrickOnly:
      return 'Brick Only';
    case TWorkAnniversaryGiftVariations.Custom:
      return 'Custom';
    default:
      return 'Deluxe';
  }
};

export const getWorkAnniversaryIcon = (variation?: TWorkAnniversaryGiftVariations): React.ReactNode => {
  switch (variation) {
    case TWorkAnniversaryGiftVariations.BrickOnly:
      return <Icon icon='empty_dashboard' />;
    case TWorkAnniversaryGiftVariations.Custom:
      return <Icon icon='star_rate' />;
    case TWorkAnniversaryGiftVariations.Mini:
      return <div className={styles.miniGiftIcon}>M</div>;
    default:
      return null;
  }
};
