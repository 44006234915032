import { ErrorPage, LoadingPage, ModalForm, TControlledFormProps } from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  TCreateUserMutation,
  TCreateUserMutationInput,
  useCreateUserMutation,
  useGetCompanyLazyQuery,
} from '../../generated/graphql';
import { toast } from 'react-toastify';
import { ApolloError, SingleExecutionResult } from '@apollo/client';
import { joiResolver } from '@hookform/resolvers/joi';
import { getUserSchema } from '../../entities/users/schema';
import { getUserFormFields } from '../../entities/users/formFields';
import styles from './InviteUserForm.module.scss';
import { COMPANIES_PATH, COMPANY_PATH, USERS_PATH } from '../../routes/paths';

export const InviteUserForm = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const onBackClick = useCallback(() => {
    if (companyId) {
      navigate(
        generatePath(`${COMPANIES_PATH}/${COMPANY_PATH}/${USERS_PATH}`, {
          companyId,
        }),
      );
    }
  }, [companyId, navigate]);

  const closeModal = useCallback(() => {
    navigate(generatePath(COMPANIES_PATH));
  }, [navigate]);

  const [getCompany, { data: getCompanyData, loading: getCompanyLoading, error: getCompanyError }] =
    useGetCompanyLazyQuery();

  useEffect(() => {
    if (companyId) {
      getCompany({
        variables: {
          id: companyId,
        },
      });
    }
  }, [companyId, getCompany]);

  const [createUser] = useCreateUserMutation();

  const onSuccess = useCallback(
    (data: SingleExecutionResult<TCreateUserMutation>) => {
      if (data.errors) {
        data.errors.forEach((err) => toast.error(err.message));
      } else {
        toast.success('Successfully invited the user.');
        onBackClick();
      }
    },
    [onBackClick],
  );

  const onSubmitError = useCallback((error: ApolloError) => {
    toast.error('There was an error creating the company.');
    if (error instanceof ApolloError) {
      error.graphQLErrors.forEach((err) => toast.error(err.message));
    }
  }, []);

  const onSubmit = useCallback<TControlledFormProps<TCreateUserMutationInput>['onValidSubmit']>(
    (formData) => {
      return createUser({
        variables: {
          input: {
            ...formData,
            companyId: companyId as string,
          },
        },
      })
        .then(onSuccess)
        .catch(onSubmitError);
    },
    [companyId, createUser, onSubmitError, onSuccess],
  );

  const userSchema = useMemo(() => {
    if (getCompanyData?.getCompany) {
      return getUserSchema({ company: getCompanyData.getCompany });
    }
  }, [getCompanyData?.getCompany]);

  if (getCompanyError) return <ErrorPage error={getCompanyError} />;
  if (getCompanyData?.getCompany == null || getCompanyLoading) return <LoadingPage />;

  if (userSchema == null) throw new Error('Company not found.');

  return (
    <ModalForm<TCreateUserMutationInput>
      onBackClick={onBackClick}
      closeModal={closeModal}
      headline='Invite User'
      controlledFormProps={{
        className: styles.form,
        fields: getUserFormFields(),
        formProps: {
          resolver: joiResolver(userSchema, {
            convert: true,
            abortEarly: false,
            stripUnknown: false,
          }),
        },
        onValidSubmit: onSubmit,
        onInvalidSubmit: (errors) => console.error(errors),
      }}
    />
  );
};
