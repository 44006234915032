import { Modal, NavigationTabs } from '@chocolate-soup-inc/cs-frontend-components';
import { ReactNode, useCallback } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { COMPANY_PATH, DETAILS_PATH, SHIPMENTS_PATH, SHIPMENT_GIFTS_PATH, SHIPMENT_PATH } from '../../../routes/paths';

import styles from './ShipmentModal.module.scss';

export type TShipmentModalProps = {
  children: ReactNode;
  headline?: string;
};

export const ShipmentModal = (props: TShipmentModalProps) => {
  const { children, headline } = props;
  const navigate = useNavigate();
  const { companyId, shipmentId } = useParams();
  const { state } = useLocation();

  const closeModal = useCallback(() => {
    navigate(state?.closePath || generatePath(SHIPMENTS_PATH));
  }, [navigate, state?.closePath]);

  const onBackClick = useCallback(() => {
    navigate(state?.backPath);
  }, [navigate, state?.backPath]);

  return (
    <Modal
      onBackClick={state?.backPath ? onBackClick : undefined}
      closeModal={closeModal}
      headline={headline}
      open={true}
      size='large'
    >
      <NavigationTabs
        options={[
          {
            leadingIcon: 'info',
            label: 'Details',
            path: generatePath(`${SHIPMENTS_PATH}/${COMPANY_PATH}/${SHIPMENT_PATH}/${DETAILS_PATH}`, {
              companyId: companyId as string,
              shipmentId: shipmentId as string,
            }),
          },
          {
            leadingIcon: 'card_giftcard',
            label: 'Gifts',
            path: generatePath(`${SHIPMENTS_PATH}/${COMPANY_PATH}/${SHIPMENT_PATH}/${SHIPMENT_GIFTS_PATH}`, {
              companyId: companyId as string,
              shipmentId: shipmentId as string,
            }),
          },
        ]}
      />
      <div className={styles.tabContent}>{children}</div>
    </Modal>
  );
};
