import { ReactNode } from 'react';
import styles from './FloatingActions.module.scss';

type TFloatingActionsProps = {
  children?: ReactNode;
};

export const FloatingActions = (props: TFloatingActionsProps) => {
  const { children } = props;

  return <div className={styles.floatingActions}>{children}</div>;
};
