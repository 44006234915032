import {
  ErrorPage,
  InfoCardInner,
  LoadingPage,
  Modal,
  TInfoCardSectionColumnProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { EMPLOYEES_PATH } from '../../../routes/paths';
import { EmployeeModal } from '../EmployeeModal/EmployeeModal';

import styles from './EmployeeDependants.module.scss';
import { useFragmentOrFetchEmployee } from '../../../entities/employees/queries';
import { TDependantType, useQueryAllEmployeeDependants } from '../../../entities/dependants/queries';
import { TDependantType as TDependantTypes } from '../../../generated/graphql';

export const EmployeeDependants = () => {
  const navigate = useNavigate();
  const { companyId, employeeId } = useParams();

  const {
    data: employee,
    error,
    loading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: companyId as string,
  });

  const {
    data: dependants,
    error: dependantsError,
    loading: dependantsLoading,
  } = useQueryAllEmployeeDependants({
    employeeId: employeeId as string,
    companyId: companyId as string,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return `${fullName} Dependants`;
    else return 'Employee Dependants';
  }, [employee?.fullName]);

  const dependantColumns = useCallback((dependant: TDependantType) => {
    const cols: TInfoCardSectionColumnProps<TDependantType>[] = [
      {
        label: 'Relationship',
        accessor: 'type',
        render: (data) => {
          if (data?.type === 'significantOther') {
            return 'Significant Other';
          } else {
            return _.capitalize(data?.type);
          }
        },
      },
      {
        label: 'Date of Birth',
        accessor: 'birthDate',
      },
      {
        label: 'First Name',
        accessor: 'firstName',
      },
    ];

    if (dependant.type === TDependantTypes.Pet) {
      cols.push({
        label: 'Pet Type',
        accessor: 'petType',
      });
    } else {
      cols.push({
        label: 'Last Name',
        accessor: 'lastName',
      });
    }

    if (dependant.type === TDependantTypes.SignificantOther) {
      cols.push({
        label: 'Donate Birthday Gift',
        render: (data) => {
          if (data?.donateBirthdayGift) {
            return 'yes';
          } else {
            return 'no';
          }
        },
      });
    }

    return cols;
  }, []);

  if (loading || dependantsLoading || !employee) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <ErrorPage error={error || dependantsError} />
      </Modal>
    );
  }

  return (
    <EmployeeModal employee={employee} headline={title}>
      <div className={styles.employeeDependants}>
        {dependants.length === 0 && <p className={styles.empty}>No dependants registered for this employee.</p>}
        {dependants.map((dependant, index) => (
          <InfoCardInner<TDependantType>
            key={dependant.id}
            sections={[
              {
                title: `Dependant #${index + 1}`,
                columns: dependantColumns(dependant),
              },
              {
                columns: [
                  {
                    label: 'Allergies & Dietary Restrictions',
                    accessor: 'foodPreferences',
                  },
                ],
              },
            ]}
            data={dependant}
          />
        ))}
      </div>
    </EmployeeModal>
  );
};
