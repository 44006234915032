import { DragStartEvent, UniqueIdentifier } from '@dnd-kit/core';
import _ from 'lodash';
import {
  createContext,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { TBoardProps } from './Board';
import { TColumnProps } from './Column';
import { calculateId } from './utils';

const BoardCardSelectionContext = createContext<{
  onCardMouseDown: (event: MouseEvent, id: UniqueIdentifier) => void;
  selectedCards: UniqueIdentifier[];
  setSelectedCards: Dispatch<SetStateAction<UniqueIdentifier[]>>;
}>({
  onCardMouseDown: () => undefined,
  selectedCards: [],
  setSelectedCards: () => undefined,
});

export type TRefProps<TCardDataType extends Record<string, any>, TColumnDataType extends Record<string, any>> = {
  allCardsWithColumnId: Record<
    UniqueIdentifier,
    TCardDataType & {
      columnId: UniqueIdentifier;
    }
  >;
  lastSelectedColumn?: TColumnProps<TCardDataType, TColumnDataType>;
  selectedCards: TCardDataType[];
  selectedCardsIds: UniqueIdentifier[];
  setSelectedCards: Dispatch<SetStateAction<UniqueIdentifier[]>>;
  onDragStart: (event: DragStartEvent) => void;
};

export type TBoardCardSelectionProviderProps<
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
> = {
  children: ReactNode;
  columns: TBoardProps<TCardDataType, TColumnDataType>['columns'];
  contextRef?: MutableRefObject<TRefProps<TCardDataType, TColumnDataType> | undefined>;
  onSelectedCardsChange?: (selectedCardsIds: UniqueIdentifier[]) => void;
};

export const BoardCardSelectionProvider = <
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
>(
  props: TBoardCardSelectionProviderProps<TCardDataType, TColumnDataType>,
) => {
  const { children, columns, contextRef, onSelectedCardsChange } = props;

  const [selectedCards, setSelectedCards] = useState<UniqueIdentifier[]>([]);
  const [lastSelectedColumn, setLastSelectedColumn] = useState<TColumnProps<TCardDataType, TColumnDataType>>();

  useEffect(() => {
    if (onSelectedCardsChange) onSelectedCardsChange(selectedCards);
  }, [onSelectedCardsChange, selectedCards]);

  type TAllCardsType = Record<UniqueIdentifier, TCardDataType & { columnId: UniqueIdentifier }>;

  const allCardsWithColumnId = useMemo(() => {
    return columns.reduce((agg, col) => {
      const columnId = calculateId<TColumnDataType>(col);
      const cards: TAllCardsType = col.cards.reduce((cardsAgg, card) => {
        const id = calculateId<TCardDataType>(card);

        cardsAgg[id] = {
          ...card.data,
          columnId,
        };

        return cardsAgg;
      }, {} as TAllCardsType);

      return {
        ...agg,
        ...cards,
      };
    }, {} as TAllCardsType);
  }, [columns]);

  useEffect(() => {
    if (selectedCards && selectedCards.length > 0) {
      const card = allCardsWithColumnId[selectedCards[0]];
      const col = columns.find((c) => calculateId(c) === card?.columnId);
      setLastSelectedColumn(col);
    } else {
      setLastSelectedColumn(undefined);
    }
  }, [allCardsWithColumnId, columns, selectedCards]);

  const onDragStart = useCallback((event: DragStartEvent) => {
    const activeId = event.active.id;

    setSelectedCards((cards) => {
      if (!cards.includes(activeId)) {
        return [activeId];
      } else {
        return cards;
      }
    });
  }, []);

  useEffect(() => {
    if (contextRef) {
      contextRef.current = {
        allCardsWithColumnId,
        lastSelectedColumn,
        selectedCards: selectedCards.map((id) => allCardsWithColumnId[id]),
        selectedCardsIds: selectedCards,
        setSelectedCards,
        onDragStart,
      };
    }
  }, [allCardsWithColumnId, contextRef, lastSelectedColumn, onDragStart, selectedCards]);

  const onCardMouseDown = useCallback(
    (event: MouseEvent, id: UniqueIdentifier) => {
      setSelectedCards((currentSelectedCards) => {
        let newSelectedCards = _.cloneDeep(currentSelectedCards);
        const index = newSelectedCards.findIndex((c) => c === id);

        const lastSelectedCardId = currentSelectedCards[currentSelectedCards.length - 1];

        const clickedCardColumnId = allCardsWithColumnId[id]?.columnId;
        const lastSelectedCardColumnId = lastSelectedCardId
          ? allCardsWithColumnId[lastSelectedCardId]?.columnId
          : undefined;

        if (event.ctrlKey || event.metaKey) {
          event.preventDefault();
          event.stopPropagation();
          if (index === -1) {
            if (lastSelectedCardId && lastSelectedCardColumnId !== clickedCardColumnId) {
              newSelectedCards = [id];
            } else {
              newSelectedCards.push(id);
            }
          } else {
            newSelectedCards.splice(index, 1);
          }
        } else if (event.shiftKey) {
          if (lastSelectedCardId && lastSelectedCardColumnId === clickedCardColumnId) {
            const targetColumn = columns.find((col) => calculateId(col) === clickedCardColumnId);

            if (targetColumn) {
              const lastClickedCardColumnIndex = targetColumn.cards.findIndex(
                (c) => calculateId(c) === lastSelectedCardId,
              );
              const currentClcikedCardColumnIndex = targetColumn.cards.findIndex((c) => calculateId(c) === id);

              const fromIndex = Math.min(lastClickedCardColumnIndex, currentClcikedCardColumnIndex);
              const toIndex = Math.max(lastClickedCardColumnIndex, currentClcikedCardColumnIndex);

              newSelectedCards = _.uniq([
                ...newSelectedCards,
                ...targetColumn.cards.slice(fromIndex, toIndex + 1).map((c) => calculateId(c)),
              ]);
            } else {
              newSelectedCards = [id];
            }
          } else {
            newSelectedCards = [id];
          }
        } else if (!newSelectedCards.includes(id)) {
          newSelectedCards = [id];
        }

        return newSelectedCards;
      });
    },
    [allCardsWithColumnId, columns],
  );

  return (
    <BoardCardSelectionContext.Provider
      value={{
        onCardMouseDown,
        selectedCards,
        setSelectedCards,
      }}
    >
      {children}
    </BoardCardSelectionContext.Provider>
  );
};

export const useBoardCardSelectionContext = () => {
  const context = useContext(BoardCardSelectionContext);
  if (context == null)
    throw new Error('useBoardCardSelectionContext must be used inside the BoardCardSelectionContext node.');

  return context;
};
