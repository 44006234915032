import {
  ConfirmationModal,
  ControlledForm,
  ErrorPage,
  FloatingMenu,
  Icon,
  IconButton,
  InfoCardInner,
  LoadingPage,
  readableDate,
  TConfirmationModalProps,
  TInfoCardSectionProps,
  TMenuItemProps,
  Tooltip,
} from '@chocolate-soup-inc/cs-frontend-components';
import { joiResolver } from '@hookform/resolvers/joi';
import _ from 'lodash';
import { useCallback, useMemo, useState, MouseEvent, useEffect } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { useShipmentAddressInfo } from '../../../entities/shipments/shared';
import { getUpdateShipmentExtraAttributesSchema } from '../../../entities/shipments/schema';
import {
  TShipmentStatuses,
  TShipStationAddressVerified,
  useDeleteShipmentMutation,
  useRecreateShipmentMutation,
  useResyncShipmentWithShipStationMutation,
  useUpdateShipmentMutation,
} from '../../../generated/graphql';
import { SHIPMENTS_PATH } from '../../../routes/paths';
import { ShipmentModal } from './ShipmentModal';

import styles from './ShipmentModalDetails.module.scss';
import { CountryName } from '../../../components/coutryName/CountryName';
import { TShipmentType, useFragmentOrFetchShipment } from '../../../entities/shipments/queries';
import { TrackingLink } from '../../../entities/shipments/utils';
import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { useFilter } from '../../../contexts/filters';
import { EditPackageInfoModal } from '../EditPackageInfoModal/EditPackageInfoModal';

type TShipmentExtraAttributesForm = Pick<TShipmentType, 'rack' | 'notes'>;

export const ShipmentModalDetails = () => {
  const { filtersModalMode } = useFilter();
  useEffect(() => {
    filtersModalMode();
  }, []); // eslint-disable-line

  const { companyId, shipmentId } = useParams();
  const navigate = useNavigate();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const {
    data: shipment,
    error,
    loading,
  } = useFragmentOrFetchShipment({
    id: shipmentId as string,
    companyId: companyId as string,
  });

  const { addressTypeIcon, readableAddressType, recipientName } = useShipmentAddressInfo(shipment);

  const closeModal = useCallback(() => {
    navigate(generatePath(SHIPMENTS_PATH));
  }, [navigate]);

  const shippingDate = useMemo(() => {
    return shipment?.actualShippingDate || shipment?.shippingDate || undefined;
  }, [shipment?.actualShippingDate, shipment?.shippingDate]);

  const [deleteShipment, { loading: deleteLoading }] = useDeleteShipmentMutation();

  const onActualDelete = useCallback(() => {
    if (shipment?.id && shipment?.companyId && shipment?._version) {
      return deleteShipment({
        variables: {
          id: shipment?.id,
          companyId: shipment?.companyId,
          version: shipment?._version,
        },
      })
        .then(() => {
          closeModal();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error deleting the shipment.');
        })
        .then(() => {
          setConfirmationProps(undefined);
        });
    }
  }, [closeModal, deleteShipment, shipment?._version, shipment?.companyId, shipment?.id]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete Shipment?',
      supportingText: `Are you sure you want to delete this shipment from company ${shipment?.company?.name}?`,
      confirmLabel: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [shipment?.company?.name, onActualDelete]);

  const [recreateShipment, { loading: recreateLoading }] = useRecreateShipmentMutation();

  const onActualRecreate = useCallback(() => {
    if (shipment?.id && shipment?.companyId) {
      return recreateShipment({
        variables: {
          id: shipment?.id,
          companyId: shipment?.companyId,
        },
      })
        .then(() => {
          closeModal();
        })
        .catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error recreating the shipment.');
        })
        .then(() => {
          setConfirmationProps(undefined);
        });
    }
  }, [closeModal, recreateShipment, shipment?.companyId, shipment?.id]);

  const onRecreateClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Recreate Shipment?',
      supportingText: `Are you sure you want to recreate this shipment gifts?`,
      confirmLabel: 'Recreate',
      onConfirmClick: onActualRecreate,
    });
  }, [onActualRecreate]);

  const topComponentMenuOptions = useMemo(() => {
    const options: TMenuItemProps[] = [];

    if (
      shipment?.status &&
      [
        TShipmentStatuses.PrintingLabel,
        TShipmentStatuses.LabelPrinted,
        TShipmentStatuses.Shipped,
        TShipmentStatuses.Attention,
        TShipmentStatuses.Delivered,
        TShipmentStatuses.Error,
        TShipmentStatuses.Canceled,
      ].includes(shipment?.status)
    ) {
      options.push({
        disabled: deleteLoading,
        label: 'Recreate Shipment',
        leadingIcon: 'control_point_duplicate',
        loading: recreateLoading,
        type: 'text',
        onClick: onRecreateClick,
      });
    }

    if (
      shipment?.status &&
      [
        TShipmentStatuses.Packaging,
        TShipmentStatuses.ReadyToShip,
        TShipmentStatuses.LabelPrinted,
        TShipmentStatuses.PrintingLabel,
      ].includes(shipment?.status)
    ) {
      options.push({
        label: 'Delete Shipment',
        loading: deleteLoading || recreateLoading,
        leadingIcon: 'delete',
        onClick: onDeleteClick,
        type: 'text',
      });
    }

    return options;
  }, [deleteLoading, onDeleteClick, onRecreateClick, recreateLoading, shipment?.status]);

  const [topComponentMenuOpen, setTopComponentMenuOpen] = useState<boolean>(false);

  const labelDataPresent = useMemo(() => {
    return shipment?.label?.labelFile?.key && shipment?.label?.labelFile?.bucket && shipment?.label?.labelFile?.region;
  }, [shipment?.label?.labelFile?.bucket, shipment?.label?.labelFile?.key, shipment?.label?.labelFile?.region]);

  const [openLabelLoading, setOpenLabelLoading] = useState<boolean>(false);

  const openLabel = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (labelDataPresent) {
        setOpenLabelLoading(true);

        Storage.get(shipment?.label?.labelFile?.key as string, {
          bucket: shipment?.label?.labelFile?.bucket as string,
          region: shipment?.label?.labelFile?.region as string,
        })
          .then((url) => {
            window.open(url, '_blank');
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error retrieving the label.');
          })
          .then(() => {
            setOpenLabelLoading(false);
          });
      }
    },
    [
      labelDataPresent,
      shipment?.label?.labelFile?.bucket,
      shipment?.label?.labelFile?.key,
      shipment?.label?.labelFile?.region,
    ],
  );

  const [resyncShipmentWithShipStation, { loading: resyncWithShipStationLoading }] =
    useResyncShipmentWithShipStationMutation();

  const syncWithShipStation = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (shipment && [TShipmentStatuses.ReadyToShip, TShipmentStatuses.PrintingLabel].includes(shipment?.status)) {
        resyncShipmentWithShipStation({
          variables: {
            id: shipment?.id,
            companyId: shipment?.companyId,
            version: shipment?._version,
          },
        })
          .then(() => {
            toast.success(
              'Shipment set for re-sync with ShipStation successfully. It might take a couple of minutes for resync to be done.',
            );
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error resyncing the shipment with ShipStation.');
          });
      }
    },
    [resyncShipmentWithShipStation, shipment],
  );

  const addressVerified = useMemo(() => {
    return shipment?.addressVerified;
  }, [shipment?.addressVerified]);

  const topComponent = useMemo(() => {
    return (
      <div className={styles.topComponent}>
        <h2 className={styles.topTitle}>Shipment</h2>
        <div className={styles.topIcons}>
          {addressVerified === TShipStationAddressVerified.Success && (
            <Tooltip message='ShipStation address verification succeeded.'>
              <Icon className={styles.addressVerifiedSuccess} icon='check_circle' />
            </Tooltip>
          )}
          {addressVerified === TShipStationAddressVerified.Failed && (
            <Tooltip message='ShipStation address verification failed.'>
              <Icon className={styles.addressVerifiedFail} icon='error_circle_rounded' />
            </Tooltip>
          )}
          {addressVerified === TShipStationAddressVerified.Warning && (
            <Tooltip message='ShipStation address verification warning.'>
              <Icon className={styles.addressVerifiedWarning} icon='warning' />
            </Tooltip>
          )}
          {[TShipmentStatuses.ReadyToShip, TShipmentStatuses.PrintingLabel].includes(shipment?.status) && (
            <Tooltip
              message={`Resync shipment with ShipStation.${
                shipment?.status === TShipmentStatuses.PrintingLabel
                  ? ` This will also try to print the label again.`
                  : ''
              }`}
            >
              <IconButton
                disabled={resyncWithShipStationLoading}
                icon='sync'
                onClick={syncWithShipStation}
                variant='standard'
              />
            </Tooltip>
          )}
          {labelDataPresent && (
            <IconButton disabled={openLabelLoading} icon='print' onClick={openLabel} variant='standard' />
          )}
          {Array.isArray(topComponentMenuOptions) && topComponentMenuOptions.length > 0 && (
            <FloatingMenu
              open={topComponentMenuOpen}
              onOpenChange={setTopComponentMenuOpen}
              options={topComponentMenuOptions}
            >
              <IconButton
                icon='more_vert'
                onClick={() => setTopComponentMenuOpen((open) => !open)}
                variant='standard'
              />
            </FloatingMenu>
          )}
        </div>
      </div>
    );
  }, [
    addressVerified,
    labelDataPresent,
    openLabel,
    openLabelLoading,
    resyncWithShipStationLoading,
    shipment?.status,
    syncWithShipStation,
    topComponentMenuOpen,
    topComponentMenuOptions,
  ]);

  const isGoogleAddressDifferent = useMemo(() => {
    if (shipment?.address != null && shipment?.address?.addressFromGoogle != null) {
      const add = shipment?.address as Record<string, any>;
      const addFromGoogle = shipment?.address?.addressFromGoogle as Record<string, any>;
      for (const [key, v] of Object.entries(addFromGoogle)) {
        if (add[key]?.toString().toLowerCase() !== v?.toString().toLowerCase()) {
          return true;
        }
      }

      return false;
    }

    return false;
  }, [shipment?.address]);

  const sections = useMemo(() => {
    const secs: TInfoCardSectionProps<TShipmentType>[] = [];

    secs.push({
      title: 'Shipment',
      topComponent: () => topComponent,
      columns: [
        {
          label: 'Delivery Date',
          render: () => readableDate(shippingDate),
        },
        {
          label: 'Tracking Number',
          render: (data) => (data ? TrackingLink(data) : null),
        },
        {
          label: 'Company',
          render: () => shipment?.company?.name,
        },
        {
          label: 'Recipient',
          render: () => {
            return (
              <div className={styles.recipient}>
                {addressTypeIcon && readableAddressType && (
                  <Tooltip autoPlacement={false} message={readableAddressType} placement='bottom'>
                    <Icon filled={true} icon={addressTypeIcon} />
                  </Tooltip>
                )}
                {recipientName}
              </div>
            );
          },
        },
      ],
    });

    const shipStationColumns = [];

    if (shipment?.shipStationOrderId) {
      shipStationColumns.push({
        label: 'ShipStation Order ID',
        render: () => shipment?.shipStationOrderId,
      });
    }

    if (shipment?.shipStationShipmentId) {
      shipStationColumns.push({
        label: 'ShipStation Shipment ID',
        render: () => shipment?.shipStationShipmentId,
      });
    }

    if (shipment?.lastShipStationResyncStartedAt != null) {
      shipStationColumns.push({
        label: 'Last ShipStation Resync',
        render: () =>
          shipment?.lastShipStationResyncStartedAt ? readableDate(shipment?.lastShipStationResyncStartedAt) : '-',
      });
    }

    if (shipment?.shipStationError) {
      shipStationColumns.push({
        label: 'ShipStation Error',
        render: () => JSON.stringify(shipment?.shipStationError),
      });
    }

    if (shipStationColumns.length > 0) {
      secs.push({
        title: 'Ship Station',
        columns: shipStationColumns,
      });
    }

    return secs;
  }, [
    topComponent,
    shippingDate,
    shipment?.company?.name,
    shipment?.shipStationOrderId,
    shipment?.shipStationShipmentId,
    shipment?.lastShipStationResyncStartedAt,
    shipment?.shipStationError,
    addressTypeIcon,
    readableAddressType,
    recipientName,
  ]);

  const [updateShipment] = useUpdateShipmentMutation();

  const onShipmentUpdate = useCallback(
    (data: TShipmentExtraAttributesForm) => {
      if (shipment?.id && shipment?.companyId && shipment?._version) {
        return updateShipment({
          variables: {
            id: shipment?.id,
            companyId: shipment?.companyId,
            version: shipment?._version,
            input: {
              rack: data.rack == null ? null : data.rack,
              notes: data.notes == null ? null : data.notes,
            },
          },
        }).catch((error) => {
          console.error(serializeError(error));
          toast.error('There was an error updating the gift.');
        });
      }
    },
    [shipment?._version, shipment?.companyId, shipment?.id, updateShipment],
  );

  const [addressLoading, setAddressLoading] = useState<boolean>(false);

  const onSelectAddress = useCallback(
    (isGoogleAddress?: boolean) => {
      if (shipment != null) {
        setAddressLoading(true);

        updateShipment({
          variables: {
            id: shipment?.id,
            companyId: shipment?.companyId,
            version: shipment?._version,
            input: {
              useGoogleAddress: isGoogleAddress,
            },
          },
        })
          .then(() => {
            toast.success('The address to use was successfully changed.');
          })
          .catch((error) => {
            console.error(serializeError(error));
            toast.error('There was an error changing which address to use.');
          })
          .then(() => {
            setAddressLoading(false);
          });
      }
    },
    [shipment, updateShipment],
  );

  const addressTopComponent = useCallback(
    (title: string, isGoogleAddress?: boolean) => {
      const selected = isGoogleAddress ? shipment?.useGoogleAddress : !shipment?.useGoogleAddress;
      return (
        <div className={styles.addressTopComponent}>
          <h2>{title}</h2>
          <Tooltip
            message={selected ? 'This address is selected for the shipment.' : 'Use this address for the shipment.'}
          >
            {selected && <IconButton disabled={addressLoading} icon='local_shipping' variant='filled_tonal' />}
            {!selected && (
              <IconButton
                disabled={addressLoading}
                icon='local_shipping'
                variant='outlined'
                onClick={() => onSelectAddress(isGoogleAddress)}
              />
            )}
          </Tooltip>
        </div>
      );
    },
    [addressLoading, onSelectAddress, shipment?.useGoogleAddress],
  );

  const [isEditPackageModalOpen, setIsEditPackageModalOpen] = useState<boolean>(false);

  const editPackageInfoButton = () => {
    return (
      <div className={styles.addressTopComponent}>
        <h2>Package</h2>
        <Tooltip message='Edit Package Info'>
          <IconButton
            disabled={false}
            icon='edit'
            onClick={() => {
              setIsEditPackageModalOpen(true);
            }}
            variant='outlined'
          />
        </Tooltip>
      </div>
    );
  };

  if (error) return <ErrorPage error={error} />;

  return (
    <>
      {isEditPackageModalOpen && (
        <EditPackageInfoModal
          shipment={shipment}
          onCancel={() => {
            setIsEditPackageModalOpen(false);
          }}
          onSuccess={() => {
            setIsEditPackageModalOpen(false);
          }}
        />
      )}
      {!_.isEmpty(confirmationProps) && (
        <ConfirmationModal
          {...confirmationProps}
          closeModal={() => setConfirmationProps(undefined)}
          confirmLoading={deleteLoading || recreateLoading}
          onCancelClick={() => setConfirmationProps(undefined)}
        />
      )}
      <ShipmentModal headline='Shipment Details'>
        {(loading || shipment == null) && <LoadingPage />}
        {!loading && shipment && (
          <div className={styles.shipmentDetailsContent}>
            <InfoCardInner<TShipmentType> data={shipment} sections={sections} />
            <div className={styles.rightPart}>
              <ControlledForm<TShipmentExtraAttributesForm>
                className={styles.form}
                fields={[
                  {
                    type: 'textField',
                    label: 'Rack',
                    name: 'rack',
                  },
                  {
                    type: 'textArea',
                    label: 'Notes',
                    name: 'notes',
                  },
                ]}
                formProps={{
                  defaultValues: _.pick(shipment, 'rack', 'notes'),
                  resolver: joiResolver(getUpdateShipmentExtraAttributesSchema(), {
                    convert: true,
                    abortEarly: false,
                    stripUnknown: false,
                  }),
                }}
                onValidSubmit={onShipmentUpdate}
              />
              {shipment?.packageInfo && (
                <InfoCardInner<Exclude<TShipmentType['packageInfo'], undefined | null>>
                  data={shipment?.packageInfo}
                  sections={[
                    {
                      title: 'Package',
                      topComponent: () => editPackageInfoButton(),
                      columns: [
                        {
                          label: 'Width',
                          render: () => shipment?.packageInfo?.width,
                        },
                        {
                          label: 'Height',
                          render: () => shipment?.packageInfo?.height,
                        },
                        {
                          label: 'Length',
                          render: () => shipment?.packageInfo?.length,
                        },
                        {
                          label: 'Weight',
                          render: () => shipment?.packageInfo?.weight,
                        },
                      ],
                    },
                  ]}
                />
              )}
            </div>
          </div>
        )}
        <div className={styles.addressContainer}>
          {shipment?.address != null && (
            <>
              <InfoCardInner
                data={shipment?.address}
                sections={[
                  {
                    title: isGoogleAddressDifferent ? 'Address' : undefined,
                    topComponent: isGoogleAddressDifferent ? () => addressTopComponent('Address', false) : undefined,
                    columns: [
                      {
                        label: 'Country',
                        render: () => {
                          return <CountryName name={shipment?.address?.country || undefined} />;
                        },
                      },
                      {
                        label: 'State',
                        render: () => shipment?.address?.state,
                      },
                      {
                        label: 'City',
                        render: () => shipment?.address?.city,
                      },
                      {
                        label: 'Address 1',
                        render: () => shipment?.address?.address1,
                      },
                      {
                        label: 'Address 2',
                        render: () => shipment?.address?.address2,
                      },
                      {
                        label: 'Postal Code',
                        render: () => shipment?.address?.zipCode,
                      },
                    ],
                  },
                ]}
              />
              {shipment?.address.addressFromGoogle != null && isGoogleAddressDifferent && (
                <InfoCardInner
                  data={shipment?.address.addressFromGoogle}
                  sections={[
                    {
                      topComponent: () => addressTopComponent('Address from Google', true),
                      columns: [
                        {
                          label: 'Country',
                          render: () => {
                            return <CountryName name={shipment?.address?.addressFromGoogle?.country || undefined} />;
                          },
                        },
                        {
                          label: 'State',
                          render: () => shipment?.address?.addressFromGoogle?.state,
                        },
                        {
                          label: 'City',
                          render: () => shipment?.address?.addressFromGoogle?.city,
                        },
                        {
                          label: 'Address 1',
                          render: () => shipment?.address?.addressFromGoogle?.address1,
                        },
                        {
                          label: 'Address 2',
                          render: () => shipment?.address?.addressFromGoogle?.address2,
                        },
                        {
                          label: 'Postal Code',
                          render: () => shipment?.address?.addressFromGoogle?.zipCode,
                        },
                      ],
                    },
                  ]}
                />
              )}
            </>
          )}
        </div>
      </ShipmentModal>
    </>
  );
};
