import { ExtendedFAB, Icon, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { CellContext, RowSelectionState } from '@tanstack/react-table';
import format from 'date-fns/format';
import _ from 'lodash';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { TablePage, TTablePageRef } from '../../../components/tablePage/TablePage';
import {
  filterByRecipient,
  TShipmentWithAddressInfo,
  useShipmentAddressInfo,
  useShipmentsWithAddressInfo,
} from '../../../entities/shipments/shared';
import { useCancelShipmentForPickupMutation, useSetShipmentAsPickedUpMutation } from '../../../generated/graphql';

import styles from './ShipmentsSetForPickup.module.scss';
import { TShipmentType, useQueryAllSetForPickupShipments } from '../../../entities/shipments/queries';
import { useFilter } from '../../../contexts/filters';
import clsx from 'clsx';
import tableStyles from '../../../components/tablePage/TablePage.module.scss';

export const RecipientCell = (context: CellContext<TShipmentType, unknown>) => {
  const { addressTypeIcon, recipientName, readableAddressType } = useShipmentAddressInfo(context.cell.row.original);

  return (
    <div className={clsx(styles.recipientCell, tableStyles.tableSpacePrimary)}>
      {addressTypeIcon && readableAddressType && (
        <Tooltip autoPlacement={false} message={readableAddressType} placement='bottom'>
          <Icon filled={true} icon={addressTypeIcon} />
        </Tooltip>
      )}
      {recipientName}
    </div>
  );
};

export const ShipmentsSetForPickup = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [selectedRows, setSelectedRows] = useState<string[]>();
  const [cancelPickupLoading, setCancelPickupLoading] = useState<boolean>(false);

  const tableRef = createRef<TTablePageRef>();

  const { data: noAddressShipments, error, loading } = useQueryAllSetForPickupShipments();

  const shipments = useShipmentsWithAddressInfo({
    shipments: noAddressShipments,
  });

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(shipments.map((s) => s.company)), 'id');
  }, [shipments]);

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const getRowId = useCallback((shipment: TShipmentType) => {
    return shipment.id;
  }, []);

  const [cancelShipmentForPickup] = useCancelShipmentForPickupMutation();

  const cancelPickup = useCallback(() => {
    setCancelPickupLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const shipment = shipments.find((s) => s.id === id);

          if (shipment == null) return resolve(undefined);
          cancelShipmentForPickup({
            variables: {
              id: shipment.id,
              companyId: shipment.companyId,
              version: shipment._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setSelectedRows(undefined);
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error canceling the pickup.');
      })
      .then(() => {
        setCancelPickupLoading(false);
      });
  }, [cancelShipmentForPickup, selectedRows, shipments, tableRef]);

  const cancelPickupClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Cancel Pickup for ${selectedRows?.length} Shipments`,
      supportingText: `Are you sure you want to cancel pickup for these ${selectedRows?.length} shipments?`,
      confirmLabel: 'Cancel Pickup',
      onConfirmClick: cancelPickup,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelPickup, selectedRows?.length, tableRef, tableRef.current]);

  const [setAsPickedUpLoading, setSetAsPickedUpLoading] = useState<boolean>(false);

  const [setShipmentAsPickedUp] = useSetShipmentAsPickedUpMutation();

  const setAsPickedUp = useCallback(() => {
    setSetAsPickedUpLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const shipment = shipments.find((s) => s.id === id);

          if (shipment == null || shipment.pickedUpAt != null) return resolve(undefined);
          setShipmentAsPickedUp({
            variables: {
              id: shipment.id,
              companyId: shipment.companyId,
              version: shipment._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setSelectedRows(undefined);
      })
      .catch((error) => {
        console.error(serializeError(error));
        toast.error('There was an error setting the shipments as picked up.');
      })
      .then(() => {
        setCancelPickupLoading(false);
      });
  }, [selectedRows, setShipmentAsPickedUp, shipments, tableRef]);

  const setAsPickedUpClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Set ${selectedRows?.length} Shipments as Picked Up?`,
      supportingText: `Are you sure you want to set these ${selectedRows?.length} shipments as picked up?`,
      confirmLabel: 'Set as Picked Up',
      onConfirmClick: setAsPickedUp,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length, setAsPickedUp, tableRef, tableRef.current]);

  const [companyFilter, setCompanyFilter] = useState<string>();
  const [recipientFilter, setRecipientFilter] = useState<string>();

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    if (companies.length > 0) {
      filtersList.push({
        includeEmptyOption: true,
        label: 'Company',
        name: 'companyId',
        onChange: (v) => {
          if (v == null) setCompanyFilter(undefined);
          else setCompanyFilter(v as string);
        },
        options: companies.map((c) => ({ label: c.name, value: c.id })),
        type: 'singleSelect',
        value: companyFilter,
      });
    }

    filtersList.push({
      label: 'Recipient',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientFilter(undefined);
        else setRecipientFilter(v);
      },
      type: 'textInput',
      value: recipientFilter,
    });

    return filtersList;
  }, [companies, companyFilter, recipientFilter]);

  const filteredShipments = useMemo(() => {
    return shipments?.filter((s) => {
      if (companyFilter != null && s.companyId !== companyFilter) {
        return false;
      }

      return filterByRecipient({
        shipment: s,
        filter: recipientFilter,
      });
    });
  }, [companyFilter, recipientFilter, shipments]);

  return (
    <TablePage
      actionsLoading={cancelPickupLoading || setAsPickedUpLoading}
      dataLoading={loading}
      error={error}
      ref={tableRef}
      title='Shipments for Pickup'
    >
      <Filters filters={filters} />
      <TableInner<TShipmentWithAddressInfo>
        data={filteredShipments}
        emptyText={
          noAddressShipments.length === 0
            ? 'No shipments set for pickup. When you set shipments for pickup, they will appear here.'
            : 'No shipments for the current filters.'
        }
        expandable={false}
        fillSelectedRows={true}
        fixedHeader={true}
        getRowId={getRowId}
        hoverableRows={!cancelPickupLoading}
        onRowSelectionChange={onRowSelectionChange}
        selectableRows={true}
        columns={[
          {
            header: 'Company Name',
            cell: ({ cell }) => {
              const { pickedUpAt, companyId } = cell.row.original;
              const company = _.compact(companies || []).find((c) => c.id === companyId);

              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {pickedUpAt && (
                    <Tooltip message={`Shipment was picked up on ${readableDate(pickedUpAt)}`}>
                      <Icon icon='task_alt' />
                    </Tooltip>
                  )}
                  {company && <span>{company.name}</span>}
                </div>
              );
            },
          },
          {
            header: 'Recipient Name',
            cell: RecipientCell,
          },
          {
            header: 'Shipping Date',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpaceTertiary)}>
                  <span>
                    {readableDate(cell.row.original.actualShippingDate || cell.row.original.shippingDate || undefined)}
                  </span>
                </div>
              );
            },
          },
          {
            header: 'Set for Pickup At',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>
                    {cell.row.original.setForPickupAt
                      ? format(new Date(cell.row.original.setForPickupAt as string), 'do MMM, yyyy hh:mmaaa')
                      : '-'}
                  </span>
                </div>
              );
            },
          },
          {
            header: 'Picked Up At',
            cell: ({ cell }) => {
              return (
                <div className={clsx(tableStyles.tableSpacePrimary)}>
                  <span>
                    {cell.row.original.pickedUpAt
                      ? format(new Date(cell.row.original.pickedUpAt as string), 'do MMM, yyyy hh:mmaaa')
                      : '-'}
                  </span>
                </div>
              );
            },
          },
        ]}
      />
      {selectedRows && selectedRows.length > 0 && (
        <div className={styles.actions}>
          <ExtendedFAB
            label={`Cancel Pickup for ${selectedRows.length} Shipments`}
            leadingIcon='exit_to_app'
            loading={cancelPickupLoading}
            onClick={cancelPickupClick}
            variant='tertiary'
          />
          <ExtendedFAB
            label={`Set ${selectedRows.length} Shipments as Picked Up`}
            leadingIcon='task_alt'
            loading={setAsPickedUpLoading}
            onClick={setAsPickedUpClick}
            variant='surface'
          />
        </div>
      )}
    </TablePage>
  );
};
