import {
  CommonButton,
  ConfirmationModal,
  ErrorPage,
  FloatingMenu,
  IconButton,
  LoadingPage,
  TableInner,
  TConfirmationModalProps,
  TMenuItemProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { TUserType, useQueryAllUsers } from '../../../entities/users/queries';
import { INVITE_PATH } from '../../../routes/paths';
import { CompanyModal } from '../CompanyModal/CompanyModal';
import { CellContext } from '@tanstack/react-table';
import { useDeleteUserMutation } from '../../../generated/graphql';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import styles from './CompanyUsers.module.scss';
import _ from 'lodash';

const CUSTOMER_SUPPORT_EMAIL = 'cs@chocolatesoup.ca';

const UserOptions = (
  props: CellContext<TUserType, unknown> & {
    setConfirmationProps: (v: any) => void;
    setActionLoading: (v: boolean) => void;
  },
) => {
  const { cell, setConfirmationProps, setActionLoading } = props;

  const { companyId, email, name, _version: version } = cell.row.original;

  const [deleteUser, { loading: deleteUserLoading }] = useDeleteUserMutation({
    variables: {
      email,
      companyId,
      version,
    },
  });

  const onActualDelete = useCallback(() => {
    setActionLoading(true);
    return deleteUser()
      .then()
      .catch((error) => {
        console.error(serializeError(error));
        toast.error(error.message);
      })
      .then(() => {
        setConfirmationProps(undefined);
        setActionLoading(false);
      });
  }, [deleteUser, setActionLoading, setConfirmationProps]);

  const onDeleteClick = useCallback(() => {
    setConfirmationProps({
      headline: 'Delete user?',
      supportingText: `Are you sure you want to delete the user ${name}?`,
      confirm: 'Delete',
      onConfirmClick: onActualDelete,
    });
  }, [name, onActualDelete, setConfirmationProps]);

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const options = useMemo(() => {
    const list: TMenuItemProps[] = [];
    const disabled = deleteUserLoading;

    if (email !== CUSTOMER_SUPPORT_EMAIL) {
      list.push({
        className: styles.removeUserMenuItem,
        disabled,
        label: 'Remove User',
        loading: deleteUserLoading,
        onClick: onDeleteClick,
        type: 'text',
      });
    }

    return list;
  }, [deleteUserLoading, email, onDeleteClick]);

  if (options.length === 0) return null;

  return (
    <div className={styles.userOptions}>
      <FloatingMenu placement='bottom-end' open={menuOpen} onOpenChange={setMenuOpen} options={options}>
        <IconButton icon='more_vert' onClick={() => setMenuOpen(!menuOpen)} variant='standard' />
      </FloatingMenu>
    </div>
  );
};

export const CompanyUsers = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const [confirmationProps, setConfirmationProps] =
    useState<Omit<TConfirmationModalProps, 'closeModal' | 'confirmLoading' | 'onCancelClick'>>();

  const [actionLoading, setActionLoading] = useState<boolean>();

  const {
    data: users,
    loading,
    error,
  } = useQueryAllUsers({
    companyId: companyId as string,
  });

  const getRowId = useCallback((user: TUserType) => {
    return user.email;
  }, []);

  const onInviteUserClick = useCallback(() => {
    navigate(generatePath(INVITE_PATH));
  }, [navigate]);

  if (error) return <ErrorPage error={error} />;

  return (
    <CompanyModal headline='Company Users'>
      {loading && <LoadingPage />}
      {!loading && (
        <>
          {!_.isEmpty(confirmationProps) && (
            <ConfirmationModal
              {...confirmationProps}
              closeModal={() => setConfirmationProps(undefined)}
              confirmLoading={actionLoading}
              onCancelClick={() => setConfirmationProps(undefined)}
            />
          )}
          <CommonButton leadingIcon='add' label='Add user' onClick={onInviteUserClick} variant='filled' />
          {users.length > 0 && (
            <TableInner<TUserType>
              columns={[
                { header: 'Name', accessorKey: 'name' },
                { header: 'Email', accessorKey: 'email' },
                {
                  header: 'Account type',
                  accessorKey: '',
                  cell: ({ cell }: CellContext<TUserType, unknown>) => {
                    const { isCompanyOwner, type, email } = cell.row.original;

                    return (
                      <span>
                        {isCompanyOwner && 'Primary Owner'}
                        {!isCompanyOwner && email === CUSTOMER_SUPPORT_EMAIL && 'Support'}
                        {!isCompanyOwner &&
                          email !== CUSTOMER_SUPPORT_EMAIL &&
                          (type === 'admin' ? 'Admin' : 'Team Leader')}
                      </span>
                    );
                  },
                },
                {
                  header: '',
                  accessorKey: 'type',
                  cell: (cell) => (
                    <UserOptions
                      {...cell}
                      setConfirmationProps={setConfirmationProps}
                      setActionLoading={setActionLoading}
                    />
                  ),
                },
              ]}
              data={users}
              emptyText='No users registered. Create the first user so the company can access the client portal.'
              getRowId={getRowId}
              virtual={false}
            />
          )}
        </>
      )}
    </CompanyModal>
  );
};
