export const ROOT_PATH = '/';
export const LOGIN_PATH = '/login';
export const OAUTH_CALLBACK_PATH = '/oauth/callback';

export const NEW_PATH = 'new';
export const EDIT_PATH = 'edit';

export const COMPANIES_PATH = '/companies';
export const COMPANY_PATH = ':companyId';
export const DETAILS_PATH = 'info';

export const USERS_PATH = 'users';
export const INVITE_PATH = 'invite';

export const SUBSCRIPTIONS_PATH = 'subscriptions';
export const NEW_HIRES_PATH = 'new-hires';

export const EVENTS_PATH = '/events';
export const UPCOMING_PATH = 'upcoming';
export const HOME_PATH = 'home';
export const OFFICE_PATH = 'office';
export const SKIPPED_EVENTS_PATH = 'skipped';

export const GIFTS_PATH = '/gifts';
export const ASSEMBLING_PATH = 'assembling';

export const GIFT_PATH = ':giftId';

export const SHIPMENTS_PATH = '/shipments';
export const SHIPMENT_PATH = ':shipmentId';
export const SHIPMENT_GIFTS_PATH = 'gifts';
export const SET_FOR_PICKUP_PATH = 'set-for-pickup';
export const TRACKING_PATH = 'tracking';

export const EMPLOYEES_PATH = '/employees';
export const EMPLOYEE_PATH = ':employeeId';

export const LOGS_PATH = '/logs';
export const EMPLOYEE_ACCOUNT_PATH = 'account';
export const DEPENDANTS_PATH = 'dependants';
export const EMPLOYEE_GIFTS_PATH = 'gifts';
export const IMPORT_PATH = 'import';
export const IMPORT_LIST_PATH = 'import/list';
export const ONE_OFF_EVENTS_PATH = 'one-off-events';
export const ONE_OFF_GIFTS_PATH = 'one-off-gifts';
