import { useDroppable } from '@dnd-kit/core';
import clsx from 'clsx';
import { useCallback, useMemo, useRef } from 'react';
import { DraggableCard, TDraggableCardProps } from './DraggableCard';
import { calculateDisabled, calculateId } from './utils';
import { useVirtualizer } from '@tanstack/react-virtual';

import styles from './Board.module.scss';

export type TColumnProps<
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
> = {
  cards: TDraggableCardProps<TCardDataType>[];
  data?: TColumnDataType;
  disabled?: boolean | ((data?: TColumnDataType) => boolean);
  id: string | number | ((data?: TColumnDataType) => string | number);
  lastSelectedColumn?: TColumnProps<TCardDataType, TColumnDataType>;
  name: string;
  onCardClick?: (card: TCardDataType) => void;
};

export const Column = <
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
>(
  props: TColumnProps<TCardDataType, TColumnDataType>,
) => {
  const { cards, data, lastSelectedColumn, name, onCardClick } = props;

  const id = useMemo(() => {
    return calculateId<TColumnDataType>(props) || props.name;
  }, [props]);

  const disabled = useMemo(() => {
    const calculatedDisabled = calculateDisabled<TColumnDataType>(props) || false;
    if (calculatedDisabled || lastSelectedColumn == null) return calculatedDisabled;

    if (
      lastSelectedColumn.data?.destinationColumns == null ||
      !Array.isArray(lastSelectedColumn.data?.destinationColumns)
    ) {
      return calculatedDisabled;
    } else {
      return !lastSelectedColumn.data.destinationColumns.includes(id);
    }
  }, [id, lastSelectedColumn, props]);

  const { active, isOver, setNodeRef } = useDroppable({
    data,
    disabled,
    id,
  });

  const isDroppable = useMemo(() => {
    return active != null && lastSelectedColumn != null && lastSelectedColumn.data?.destinationColumns.includes(id);
  }, [active, id, lastSelectedColumn]);

  const getCardKey = useCallback(
    (index: number) => {
      const card = cards[index];

      return calculateId<TCardDataType>(card);
    },
    [cards],
  );

  const columnBodyRef = useRef<HTMLDivElement>(null);

  const rowIndexAttribute = 'data-row-index';

  const rowVirtualizer = useVirtualizer<HTMLElement | null, HTMLDivElement>({
    estimateSize: () => 175,
    getScrollElement: () => columnBodyRef?.current,
    count: cards.length,
    overscan: 1,
    getItemKey: getCardKey,
    indexAttribute: rowIndexAttribute,
  });

  const totalSize = rowVirtualizer.getTotalSize();
  const virtualRows = rowVirtualizer.getVirtualItems();

  const padding = useMemo(() => {
    return {
      top: virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0,
      bottom: virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0,
    };
  }, [totalSize, virtualRows]);

  // const shouldShowIsOver = useMemo(() => {
  //   if (!isOver) return false;
  //   if (over?.data?.current?.destinationColumns == null) return true;

  //   if (over.data.current?.destinationColumns.includes(id)) return true;
  //   return false;
  // }, [id, isOver, over?.data]);

  return (
    <div
      ref={disabled ? undefined : setNodeRef}
      className={clsx(styles.column, isOver && styles.isOver, isDroppable && styles.isDroppable)}
      key={`${id}-${cards.length}`}
    >
      <h2 className={styles.columnTitle}>{name}</h2>
      <div className={styles.columnBody} ref={columnBodyRef}>
        <div className={styles.columnBodyInner}>
          <div className={styles.columnFilling} style={{ height: `${padding.top}px` }} />
          {virtualRows.map((virtualRow) => {
            const card = cards[virtualRow.index];
            return (
              <DraggableCard<TCardDataType>
                key={virtualRow.key}
                rowIndexAttribute={virtualRow.index}
                rowIndexAttributeName={rowIndexAttribute}
                measureElement={rowVirtualizer.measureElement}
                onCardClick={onCardClick}
                {...card}
              />
            );
          })}
          <div className={styles.columnFilling} style={{ height: `${padding.bottom}px` }} />
        </div>
      </div>
    </div>
  );
};
