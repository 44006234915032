import { ExtendedFAB, Icon, readableDate, TableInner, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import { RowSelectionState } from '@tanstack/react-table';
import { createRef, useCallback, useEffect, useMemo, useState } from 'react';
import { CountryName } from '../../../../components/coutryName/CountryName';
import { Filters, TFiltersProps } from '../../../../components/filters/Filters';
import { FloatingActions } from '../../../../components/floatingActions/FloatingActions';
import { TablePage, TTablePageRef } from '../../../../components/tablePage/TablePage';
import { getReadableRelationshipType } from '../../../../entities/dependants/shared';
import { translateEventType } from '../../../../entities/events/shared';
import { isDependant } from '../../../../entities/recipients/shared';
import { TEventTypes, useCreateEventGiftMutation, useSkipEventMutation } from '../../../../generated/graphql';

import styles from './UpcomingHomeEvents.module.scss';
import tableStyles from '../../../../components/tablePage/TablePage.module.scss';
import { useEventsFilters } from '../../shared';
import { useGroupedUpcomingSubscriptionHomeEvents } from '../../../../entities/events/queries';
import { useFilter } from '../../../../contexts/filters';
import clsx from 'clsx';

export const UpcomingHomeEvents = () => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const [selectedRows, setSelectedRows] = useState<string[]>();
  const [skipLoading, setSkipLoading] = useState<boolean>(false);
  const [createGiftsLoading, setCreateGiftsLoading] = useState<boolean>(false);

  const { data: homeEvents, flatData: flatHomeEvents, error, loading } = useGroupedUpcomingSubscriptionHomeEvents();
  const [skipEvent] = useSkipEventMutation({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
  const [createEventGift] = useCreateEventGiftMutation({
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });

  const tableRef = createRef<TTablePageRef>();

  const onRowSelectionChange = useCallback((rowSelection: RowSelectionState) => {
    setSelectedRows(Object.keys(rowSelection));
  }, []);

  const getRowId = useCallback((event: (typeof homeEvents)[number]) => {
    return event.id;
  }, []);

  const skipEvents = useCallback(() => {
    setSkipLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = flatHomeEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          skipEvent({
            variables: {
              id: event.id,
              companyId: event.companyId,
              version: event._version,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setSkipLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flatHomeEvents, selectedRows, skipEvent, tableRef, tableRef.current]);

  const createGifts = useCallback(() => {
    setCreateGiftsLoading(true);
    return Promise.all(
      (selectedRows || []).map((id) => {
        return new Promise((resolve, reject) => {
          const event = flatHomeEvents.find((e) => e.id === id);

          if (event == null) return resolve(undefined);

          let group: string | undefined;
          if (event.groupedToEventId && selectedRows?.includes(event.groupedToEventId)) {
            group = event.groupedToEventId;
          }

          return createEventGift({
            variables: {
              eventId: event.id,
              companyId: event.companyId,
              eventVersion: event._version,
              group,
            },
          })
            .then(resolve)
            .catch(reject);
        });
      }),
    )
      .then()
      .then(() => {
        tableRef.current?.resetConfirmationModal();
        setCreateGiftsLoading(false);
        setSelectedRows(undefined);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createEventGift, flatHomeEvents, selectedRows, tableRef, tableRef.current]);

  const onSkipEventsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Skip ${selectedRows?.length} Events?`,
      supportingText: `Are you sure you want to skip these ${selectedRows?.length} events?`,
      confirmLabel: 'Skip',
      onConfirmClick: skipEvents,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows?.length, skipEvents, tableRef, tableRef.current]);

  const onCreateGiftsClick = useCallback(() => {
    tableRef.current?.setConfirmationProps({
      headline: `Create ${selectedRows?.length} Gifts?`,
      supportingText: `Are you sure you want to create these ${selectedRows?.length} gifts?`,
      confirmLabel: 'Create',
      onConfirmClick: createGifts,
      open: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createGifts, selectedRows?.length, tableRef, tableRef.current]);

  const {
    companies,
    filterByCompany,
    setCompanyFilter,
    companyFilter,
    months,
    filterByMonth,
    setMonthFilter,
    monthFilter,
    filterByRecipientName,
    setRecipientNameFilter,
    recipientNameFilter,
  } = useEventsFilters({ events: homeEvents });

  const [eventTypeFilter, setEventTypeFilter] = useState<TEventTypes>();

  const filterByEventType = useCallback(
    (e: (typeof homeEvents)[number]) => {
      if (eventTypeFilter && e.type !== eventTypeFilter) return false;
      return true;
    },
    [eventTypeFilter],
  );

  const filters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [
      {
        includeEmptyOption: true,
        type: 'singleSelect',
        label: 'Date',
        name: 'date',
        onChange: (v) => {
          if (v == null) setMonthFilter(undefined);
          else setMonthFilter(v as string);
        },
        options: months.map((m) => ({ label: readableDate(m, 'MMM, yyyy') as string, value: m })),
        value: monthFilter,
      },
    ];

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Company',
      name: 'companyId',
      onChange: (v) => {
        if (v == null) setCompanyFilter(undefined);
        else setCompanyFilter(v as string);
      },
      options: companies.map((c) => ({ label: c.name, value: c.id })),
      value: companyFilter,
    });

    filtersList.push({
      type: 'textInput',
      label: 'Recipient Name',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientNameFilter(undefined);
        else setRecipientNameFilter(v as string);
      },
      value: recipientNameFilter,
    });

    filtersList.push({
      includeEmptyOption: true,
      type: 'singleSelect',
      label: 'Event Type',
      name: 'eventType',
      onChange: (v) => {
        if (v == null) setEventTypeFilter(undefined);
        else setEventTypeFilter(v as TEventTypes);
      },
      options: [
        {
          label: 'Birthday',
          value: TEventTypes.Birthday,
        },
        {
          label: 'Work Anniversary',
          value: TEventTypes.WorkAnniversary,
        },
      ],
      value: eventTypeFilter,
    });

    return filtersList;
  }, [
    companies,
    companyFilter,
    eventTypeFilter,
    monthFilter,
    months,
    recipientNameFilter,
    setCompanyFilter,
    setMonthFilter,
    setRecipientNameFilter,
  ]);

  const filteredHomeEvents = useMemo(() => {
    return homeEvents.filter((e) => {
      return filterByCompany(e) && filterByMonth(e) && filterByEventType(e) && filterByRecipientName(e);
    });
  }, [filterByCompany, filterByEventType, filterByMonth, filterByRecipientName, homeEvents]);

  const getRowClassName = useCallback(
    (index: number) => {
      const event = filteredHomeEvents[index];
      if (event?.address == null) return styles.noAddressRow;
    },
    [filteredHomeEvents],
  );

  return (
    <TablePage
      actionsLoading={createGiftsLoading || skipLoading}
      dataLoading={loading}
      error={error}
      ref={tableRef}
      title='Upcoming Home Events'
    >
      <Filters filters={filters} />
      <TableInner<(typeof homeEvents)[number]>
        data={filteredHomeEvents}
        emptyText={
          homeEvents.length === 0 ? 'No upcoming home events.' : 'No upcoming home events for the current filters.'
        }
        expandable={true}
        fillSelectedRows={true}
        fixedHeader={true}
        forceExpanded={true}
        getRowId={getRowId}
        getRowClassName={getRowClassName}
        hideSubRowsSelect={true}
        hoverableRows={!skipLoading && !createGiftsLoading}
        onRowSelectionChange={onRowSelectionChange}
        selectableRows={true}
        selectingChildSelectsFather={true}
        virtual={true}
        columns={[
          {
            header: 'Company Name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  {cell.row.original.wasPaused && (
                    <Tooltip message='This event was recently unpaused.'>
                      <Icon icon='play_circle' />
                    </Tooltip>
                  )}
                  <span>{cell.row.original.company?.name}</span>
                </div>
              );
            },
          },
          {
            header: 'Recipient Name',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpacePrimary)}>
                  <span>{cell.row.original.recipient?.fullName}</span>
                </div>
              );
            },
          },
          {
            header: 'Shipping Date',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.shippingDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Type',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpaceSecondary)}>
                  <span>{translateEventType(cell.row.original.type)}</span>
                </div>
              );
            },
          },
          {
            header: 'Event Date',
            cell: ({ cell }) => {
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpaceTertiary)}>
                  <span>{readableDate(cell.row.original.eventDate)}</span>
                </div>
              );
            },
          },
          {
            header: 'Relationship',
            cell: ({ cell }) => {
              const recipient = cell.row.original.recipient;
              return (
                <div className={clsx(styles.companyName, tableStyles.tableSpaceTertiary)}>
                  <span>
                    {recipient && isDependant(recipient)
                      ? `${getReadableRelationshipType(recipient)} of ${recipient.employee?.fullName}`
                      : 'Employee'}
                  </span>
                </div>
              );
            },
          },
          {
            header: 'Country',
            cell: ({ cell }) => {
              if (cell.row.original.address != null) {
                return (
                  <div className={clsx(styles.missingAddress, tableStyles.tableSpacePrimary)}>
                    <CountryName name={cell.row.original.address.country || undefined} />
                  </div>
                );
              } else {
                return (
                  <div className={clsx(styles.missingAddress, tableStyles.tableSpacePrimary)}>
                    <Icon icon='warning' />
                    <span>Missing address</span>
                  </div>
                );
              }
            },
          },
        ]}
      />
      {selectedRows && selectedRows.length > 0 && (
        <FloatingActions>
          <ExtendedFAB
            disabled={createGiftsLoading}
            label={`Skip ${selectedRows.length} Events`}
            leadingIcon='exit_to_app'
            loading={skipLoading}
            onClick={onSkipEventsClick}
            variant='tertiary'
          />
          <ExtendedFAB
            disabled={skipLoading}
            label={`Create ${selectedRows.length} Gifts`}
            leadingIcon='add'
            loading={createGiftsLoading}
            onClick={onCreateGiftsClick}
            variant='surface'
          />
        </FloatingActions>
      )}
    </TablePage>
  );
};
