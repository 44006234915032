import { Card, Chip, getDateWithTimezoneFixed, Icon, Tooltip } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { TDraggableCardRenderOptions } from '../../../components/board/DraggableCard';
import { getReadableWorkAnniversaryVariation, getWorkAnniversaryIcon } from '../../../entities/companies/shared';
import { getReadableRelationshipType } from '../../../entities/dependants/shared';
import { getReadableGiftType, getRemovedInfoFromRecipientData, isDonationGift } from '../../../entities/gifts/shared';
import { isDependant } from '../../../entities/recipients/shared';

import styles from './GiftCard.module.scss';
import { TDeliveryMethod, TGiftTypes, TWorkAnniversaryGiftVariations } from '../../../generated/graphql';
import { TGroupedGiftType } from '../../../entities/gifts/queries';
import { getReadableDeliveryMethodType } from '../../../entities/addresses/shared';

export type TGiftCardProps = {
  filteredGifts?: TGroupedGiftType[];
  gift: TGroupedGiftType;
  options?: TDraggableCardRenderOptions & {
    hoverDisabled?: boolean;
  };
};

export const GiftCard = (props: TGiftCardProps) => {
  const {
    filteredGifts,
    gift,
    options: {
      hoverDisabled = false,
      isSelected,
      isDragging,
      measureElement,
      overlay,
      rowIndexAttribute,
      rowIndexAttributeName,
    } = {},
  } = props;

  const totalGroupItems = useMemo(() => {
    return gift.groupedGifts?.length || 1;
  }, [gift.groupedGifts?.length]);

  const totalColumnGroupItems = useMemo(() => {
    return gift.groupedGifts?.filter((g) => g.status === gift.status).length || 1;
  }, [gift.groupedGifts, gift.status]);

  const groupedColumnGifts = useMemo(() => {
    return gift.groupedGifts?.filter((g) => g.status === gift.status);
  }, [gift.groupedGifts, gift.status]);

  const filteredGroupedColumnGifts = useMemo(() => {
    return groupedColumnGifts?.filter((g) => filteredGifts?.find((g1) => g1.id === g.id) != null);
  }, [filteredGifts, groupedColumnGifts]);

  const groupColumnPosition = useMemo(() => {
    if (groupedColumnGifts) {
      return groupedColumnGifts.findIndex((g) => g.id === gift.id);
    } else {
      return 0;
    }
  }, [gift.id, groupedColumnGifts]);

  const isGroupFirst = useMemo(() => {
    if (gift.groupedGifts == null || gift.groupedGifts.length === 0) return false;

    if (filteredGifts && filteredGroupedColumnGifts) {
      const index = filteredGroupedColumnGifts?.findIndex((g) => g.id === gift.id);

      return index === 0;
    } else {
      return groupColumnPosition === 0;
    }
  }, [filteredGifts, filteredGroupedColumnGifts, gift.groupedGifts, gift.id, groupColumnPosition]);

  const isGroupLast = useMemo(() => {
    if (gift.groupedGifts == null || gift.groupedGifts.length === 0) return false;

    if (filteredGifts && filteredGroupedColumnGifts) {
      const index = filteredGroupedColumnGifts?.findIndex((g) => g.id === gift.id);
      return index === filteredGroupedColumnGifts.length - 1;
    } else {
      return groupColumnPosition + 1 === totalColumnGroupItems;
    }
  }, [
    filteredGifts,
    filteredGroupedColumnGifts,
    gift.groupedGifts,
    gift.id,
    groupColumnPosition,
    totalColumnGroupItems,
  ]);

  const groupFilteredAmount = useMemo(() => {
    if (filteredGroupedColumnGifts && groupedColumnGifts) {
      return groupedColumnGifts.length - filteredGroupedColumnGifts.length;
    } else {
      return 0;
    }
  }, [filteredGroupedColumnGifts, groupedColumnGifts]);

  const isFiltered = useMemo(() => {
    return groupFilteredAmount !== 0;
  }, [groupFilteredAmount]);

  const isDonation = useMemo(() => {
    return isDonationGift({
      gift,
    });
  }, [gift]);

  const isAlert = useMemo(() => {
    return gift.alerts != null && gift.alerts.length > 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gift.alerts, gift.alerts?.length]);

  const missingAddress = useMemo(() => {
    return gift.addressId == null;
  }, [gift.addressId]);

  const delivery = getReadableDeliveryMethodType(gift) as string;

  return (
    <div
      className={clsx(
        !overlay && gift.group && styles.group,
        !overlay && isGroupFirst && styles.groupFirst,
        !overlay && isGroupLast && styles.groupLast,
        !overlay && isFiltered && styles.groupFiltered,
      )}
      data-group={`${totalColumnGroupItems} / ${totalGroupItems}`}
      data-filtered-amount={groupFilteredAmount}
      ref={measureElement}
      {...(rowIndexAttributeName ? { [rowIndexAttributeName]: rowIndexAttribute } : {})}
    >
      <Card
        className={clsx(
          styles.card,
          (isAlert || missingAddress) && styles.alert,
          gift.company?.deliveryMethod !== 'home' && styles.office,
        )}
        readOnly={hoverDisabled}
        variant={isSelected ? 'elevated' : 'outlined'}
      >
        <span className={styles.companyName}>{gift.company?.name}</span>
        <div className={styles.icons}>
          {gift.recreatedFromId && (
            <Tooltip message='This gift was recreated from a shipment.'>
              <Icon icon='dynamic_feed' />
            </Tooltip>
          )}
          {gift.type === TGiftTypes.WorkAnniversary &&
            gift.company?.workAnniversaryGiftVariation != null &&
            gift.company.workAnniversaryGiftVariation !== TWorkAnniversaryGiftVariations.Deluxe && (
              <Tooltip
                message={`Work Anniversary Variation: ${getReadableWorkAnniversaryVariation(
                  gift.company.workAnniversaryGiftVariation,
                )}`}
              >
                {getWorkAnniversaryIcon(gift.company.workAnniversaryGiftVariation)}
              </Tooltip>
            )}
          {isDonation && (
            <Tooltip message='Donation enabled'>
              <Icon icon='volunteer_activism' />
            </Tooltip>
          )}
          {missingAddress && (
            <Tooltip message='Missing address.'>
              <Icon icon='not_listed_location' />
            </Tooltip>
          )}
          {gift.alerts?.map((a, i) => (
            <Tooltip key={i} message={a?.message as string}>
              <Icon icon='warning' />
            </Tooltip>
          ))}
        </div>
        <span className={styles.recipientName}> {gift.recipient?.fullName}</span>
        {!gift.recipient && <span className={styles.recipientName}>{getRemovedInfoFromRecipientData(gift)}</span>}
        {gift.recipient && isDependant(gift.recipient) && (
          <span className={styles.relationship}>
            {/* <Icon className={styles.relationshipIcon} icon='family_restroom' filled={true} weight={100} /> */}
            {getReadableRelationshipType(gift.recipient)} of {gift.recipient.employee?.fullName}
          </span>
        )}
        <div className={styles.tags}>
          <Tooltip autoPlacement={false} disabled={isDragging} message='Shipping Date Date' placement='bottom'>
            <Chip
              innerContainerClassName={styles.chip}
              leadingIcon='local_shipping'
              leadingIconProps={{
                filled: true,
              }}
              label={format(getDateWithTimezoneFixed(gift.shippingDate) as Date, 'dd/MM/yyyy')}
              readonly={true}
              variant='input'
            />
          </Tooltip>
          <Tooltip autoPlacement={false} disabled={isDragging} message='Event Date' placement='bottom'>
            <Chip
              innerContainerClassName={styles.chip}
              leadingIcon='event'
              leadingIconProps={{
                filled: true,
              }}
              label={format(getDateWithTimezoneFixed(gift.eventDate) as Date, 'dd/MM/yyyy')}
              readonly={true}
              variant='input'
            />
          </Tooltip>
          {delivery && (
            <Tooltip autoPlacement={false} disabled={isDragging} message='Delivery Method' placement='bottom'>
              <Chip
                innerContainerClassName={styles.chip}
                leadingIcon={delivery.toLowerCase() === TDeliveryMethod.Home ? 'home' : 'business'}
                leadingIconProps={{
                  filled: true,
                }}
                label={delivery}
                readonly={true}
                variant='input'
              />
            </Tooltip>
          )}
          {gift.type && (
            <Tooltip
              autoPlacement={false}
              disabled={isDragging}
              message={`${gift.type === TGiftTypes.Other ? 'Custom ' : ''}Gift Type`}
              placement='bottom'
            >
              <Chip
                innerContainerClassName={styles.chip}
                leadingIcon={'card_giftcard'}
                leadingIconProps={{
                  filled: true,
                }}
                label={(gift.type === TGiftTypes.Other ? gift.customType : getReadableGiftType(gift)) as string}
                readonly={true}
                variant='input'
              />
            </Tooltip>
          )}
        </div>
      </Card>
    </div>
  );
};
