import {
  type ReactNode,
  createContext,
  useState,
  useContext,
  useEffect,
  SetStateAction,
  Dispatch,
  useCallback,
} from 'react';
import { useSearchParams } from 'react-router-dom';

interface FilterProviderProps {
  children: ReactNode;
}

interface FilterContextProps {
  onFilterChange: (filterName: string, updatedFilter: string) => void;
  setFilters: Dispatch<SetStateAction<string[]>>;
  setFiltersUpdaters: Dispatch<SetStateAction<Record<string, any>>>;
  filtersModalMode: () => void;
  filtersPageMode: () => void;
}

const FilterContext = createContext<FilterContextProps | undefined>(undefined);

export const FilterProvider: React.FC<FilterProviderProps> = (props: FilterProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [keepFilters, setKeepFilters] = useState(true);
  const [showParams, setShowParams] = useState(true);
  const [filters, setFilters] = useState<string[]>([]);
  const [filtersValues, setFiltersValues] = useState<Record<string, string | undefined>>({});
  const [filtersUpdaters, setFiltersUpdaters] = useState<Record<string, any>>({});

  const onFilterChange = useCallback(
    (filterName: string, updatedFilter: string) => {
      if (!(filterName in filtersUpdaters)) return;
      if (updatedFilter === '') {
        setFiltersValues((prev) => ({ ...prev, [filterName]: undefined }));
        filtersUpdaters[filterName](undefined);
        searchParams.delete(filterName);
      } else {
        setFiltersValues((prev) => ({ ...prev, [filterName]: updatedFilter }));
        filtersUpdaters[filterName](updatedFilter);
        searchParams.set(filterName, updatedFilter);
      }
      if (showParams) setSearchParams(searchParams);
      else setSearchParams({});
    },
    [filtersUpdaters, searchParams, setSearchParams, showParams],
  );

  const resetFilters = useCallback(() => {
    filters.forEach((e) => {
      onFilterChange(e, '');
    });
  }, [filters, onFilterChange]);

  const filtersPageMode = useCallback(() => {
    setShowParams(true);
    if (keepFilters) setKeepFilters(false);
    else resetFilters();
  }, [keepFilters, resetFilters]);

  const filtersModalMode = useCallback(() => {
    setShowParams(false);
    setKeepFilters(true);
  }, []);

  useEffect(() => {
    filters.forEach((e) => {
      if (e in filtersValues) onFilterChange(e, filtersValues[e] ?? '');
      if (searchParams.has(e)) onFilterChange(e, searchParams.get(e) ?? '');
    });
  }, [filters]); // eslint-disable-line

  return (
    <FilterContext.Provider
      value={{
        onFilterChange,
        setFilters,
        setFiltersUpdaters,
        filtersModalMode,
        filtersPageMode,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (context == null) {
    throw new Error('useFilter must be used inside the FilterProvider node.');
  }

  return context;
};
