import { TDependantType } from '../../generated/graphql';
import { TRecipientType, isDependant } from '../recipients/shared';

export const getReadableRelationshipType = (recipient?: TRecipientType) => {
  if (recipient == null || !isDependant(recipient)) return null;

  switch (recipient.type) {
    case TDependantType.Child:
      return 'Child';
    case TDependantType.Pet:
      return recipient.petType;
    case TDependantType.SignificantOther:
      // return 'Significant Other';
      return 'Spouse';
    default:
      return null;
  }
};
