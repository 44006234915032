import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';
import { Auth } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { AuthOptions, AUTH_TYPE, createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { cache } from './cache';

const url = process.env.REACT_APP_API_URL as string;
const region = process.env.REACT_APP_REGION as string;

const authOptions: AuthOptions = {
  type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  jwtToken: () => {
    return Auth.currentSession().then((session) => {
      return session.getAccessToken().getJwtToken();
    });
  },
};

const httpLink = new HttpLink({ uri: url });

const link = ApolloLink.from([
  createAuthLink({
    url,
    region,
    auth: authOptions,
  }),
  createSubscriptionHandshakeLink(
    {
      url,
      region,
      auth: authOptions,
    },
    httpLink,
  ),
]);

export const client = new ApolloClient({
  link,
  cache,
  connectToDevTools: true,
});
