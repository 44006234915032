import { useCallback } from 'react';
import { TOnDrop, Uploader } from '../../../../components/uploader/Uploader';
import { ErrorPage, Link, LoadingPage, Select } from '@chocolate-soup-inc/cs-frontend-components';

import styles from '../EmployeesImport.module.scss';
import { useQueryAllCompanies } from '../../../../entities/companies/queries';

type TFirstStepProps = {
  companyId?: string;
  file?: File;
  setCompanyId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
};

export const FirstStep = (props: TFirstStepProps) => {
  const { companyId, file, setCompanyId, setFile } = props;

  const onDrop: TOnDrop = useCallback(
    (acceptedFiles) => {
      if (setFile) setFile(acceptedFiles[0]);
    },
    [setFile],
  );

  const { data: companies, error, loading } = useQueryAllCompanies();

  if (error) {
    return <ErrorPage error={error} />;
  }

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <div className={styles.content}>
      <Select
        label='Company'
        multiple={false}
        name='companyId'
        onChange={(v) => {
          setCompanyId(v as string);
        }}
        options={companies.map((c) => {
          return {
            label: c.name,
            value: c.id,
          };
        })}
        value={companyId}
      />
      <p className={styles.supportText}>
        {
          'Upload an Excel file to import all the employees in the file to a company. The file must follow the format of '
        }
        <Link
          href='https://docs.google.com/spreadsheets/d/1zQmGOD3vhY7xSaBut8fBclrox0e1is668mt7cMCCNhw/edit?usp=sharing'
          label='this sheet'
        />
        . It must contain only one employee per row.
      </p>
      <Uploader file={file} onDrop={onDrop} />
    </div>
  );
};
