import { TGiftTypes } from '../../generated/graphql';
import { isDependant } from '../recipients/shared';
import { TGiftType } from './queries';

export const getGiftRecipientType = (gift: TGiftType) => {
  if (gift.recipient == null) return undefined;

  if (isDependant(gift.recipient)) {
    switch (gift.recipient.type) {
      case 'child':
        return 'Child';
      case 'significantOther':
        return 'Signficant Other';
      case 'pet':
        return gift.recipient.petType || 'Pet';
    }
  } else {
    return 'Employee';
  }
};

export const getGiftEventType = (gift: TGiftType) => {
  switch (gift?.type) {
    case TGiftTypes.Birthday:
      return 'Birthday';
    case TGiftTypes.WorkAnniversary:
      return 'Work Anniversary';
    case TGiftTypes.NewHire:
    case 'newHire':
      return 'New Hire';
  }
};
