import { useBoardCardSelectionContext } from './BoardCardSelectionContext';
import { calculateId } from './utils';

import styles from './Board.module.scss';
import { TColumnProps } from './Column';

export type TDragOverlayComponent<
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
> = {
  columns: TColumnProps<TCardDataType, TColumnDataType>[];
  activeId: string | number;
};

export const DragOverlayComponent = <
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
>(
  props: TDragOverlayComponent<TCardDataType, TColumnDataType>,
) => {
  const { activeId, columns } = props;
  const { selectedCards } = useBoardCardSelectionContext();

  let card;
  for (const col of columns) {
    card = col.cards.find((c) => {
      return activeId === calculateId<TCardDataType>(c);
    });
    if (card != null) break;
  }

  if (card) {
    return (
      <div className={styles.dragOverlay}>
        {card.render(card.data, { isDragging: true, overlay: true })}
        {selectedCards.length > 1 && <span className={styles.dragOverlayCounter}>{selectedCards.length}</span>}
      </div>
    );
  } else {
    return null;
  }
};
