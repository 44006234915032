import Joi from 'joi';
import _ from 'lodash';
import { clearableField } from '../shared/clearableField';

export const getAddressSchema = () => {
  return Joi.object()
    .unknown(false)
    .keys({
      id: Joi.forbidden(),
      companyId: Joi.forbidden(),
      address1: Joi.string().trim().empty(Joi.valid(null, '')).required(),
      address2: clearableField(Joi.string().trim()),
      city: Joi.string().trim().empty(Joi.valid(null, '')).required(),
      state: Joi.string().trim().empty(Joi.valid(null, '')).required(),
      zipCode: Joi.string().trim().empty(Joi.valid(null, '')).required(),
      country: Joi.string().trim().empty(Joi.valid(null, '')).required(),
    });
};

export const makeAddressSchemaOptional = () => {
  return Joi.object({
    address1: Joi.string().trim().valid(null, ''),
    address2: Joi.string().trim().valid(null, ''),
    city: Joi.string().trim().valid(null, ''),
    state: Joi.string().trim().valid(null, ''),
    zipCode: Joi.string().trim().valid(null, ''),
    country: Joi.string().trim().valid(null, ''),
  });
};

export const getAddressDefaultValues = (address?: Record<string, any> | null) => {
  return _.pick(address || {}, 'address1', 'address2', 'city', 'state', 'zipCode', 'country');
};
