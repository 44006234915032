import { TAddressFieldsFragment } from '../../generated/graphql';

export const calculatedShippingDate = (date: string | Date, address?: TAddressFieldsFragment) => {
  const refDate = new Date(date);

  let deliveryDays: number;

  if (
    address &&
    address.country?.toLowerCase() === 'can' &&
    ['on', 'ontario', 'qc', 'quebec'].includes(address.state?.toLowerCase() || '')
  ) {
    deliveryDays = 7;
  } else {
    deliveryDays = 14;
  }
  return new Date(refDate.setDate(refDate.getDate() - deliveryDays));
};
