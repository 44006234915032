import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  UniqueIdentifier,
  useSensor,
} from '@dnd-kit/core';
import { useCallback, useRef, useState } from 'react';
import { Column, TColumnProps } from './Column';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';

import styles from './Board.module.scss';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { calculateId } from './utils';
import { BoardCardSelectionProvider, TRefProps } from './BoardCardSelectionContext';
import { DragOverlayComponent } from './DragOverlay';

export type TBoardProps<
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
> = {
  columns: TColumnProps<TCardDataType, TColumnDataType>[];
  onDragEnd?: (event: DragEndEvent, selectedCards: TCardDataType[]) => void;
  onDragStart?: (event: DragStartEvent, selectedCards: TCardDataType[]) => void;
  onCardClick?: (card: TCardDataType) => void;
  onSelectedCardsChange?: (selectedCardsIds: UniqueIdentifier[]) => void;
};

export const Board = <
  TCardDataType extends Record<string, any> = Record<string, any>,
  TColumnDataType extends Record<string, any> = Record<string, any>,
>(
  props: TBoardProps<TCardDataType, TColumnDataType>,
) => {
  const {
    columns,
    onDragEnd: propsOnDragEnd,
    onDragStart: propsOnDragStart,
    onCardClick,
    onSelectedCardsChange,
  } = props;

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);

  const onDragCancel = useCallback(() => {
    setActiveId(null);
    const { setSelectedCards } = boardCardSelectionProviderRef.current || {};

    if (setSelectedCards) setSelectedCards([]);
  }, []);

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      setActiveId(null);
      const { setSelectedCards, selectedCards = [] } = boardCardSelectionProviderRef.current || {};

      if (propsOnDragEnd) propsOnDragEnd(event, selectedCards);

      if (setSelectedCards) setSelectedCards([]);
    },
    [propsOnDragEnd],
  );

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      const { selectedCards = [] } = boardCardSelectionProviderRef.current || {};

      boardCardSelectionProviderRef.current?.onDragStart(event);
      if (propsOnDragStart) propsOnDragStart(event, selectedCards);
      setActiveId(event.active.id);
    },
    [propsOnDragStart],
  );

  const getColKey = useCallback((col: TColumnProps<TCardDataType, TColumnDataType>) => {
    return calculateId<TColumnDataType>(col) || col.name;
  }, []);

  const pointerSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 200,
      tolerance: 1000,
    },
  });

  const boardCardSelectionProviderRef = useRef<TRefProps<TCardDataType, TColumnDataType>>();

  return (
    <BoardCardSelectionProvider<TCardDataType, TColumnDataType>
      columns={columns}
      contextRef={boardCardSelectionProviderRef}
      onSelectedCardsChange={onSelectedCardsChange}
    >
      <div className={clsx(styles.board, styles[`columns${columns.length}`])}>
        <DndContext
          modifiers={[restrictToWindowEdges]}
          onDragCancel={onDragCancel}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          sensors={[pointerSensor]}
          collisionDetection={pointerWithin}
        >
          {columns.map((col) => {
            return (
              <Column<TCardDataType, TColumnDataType>
                key={getColKey(col)}
                {...col}
                lastSelectedColumn={boardCardSelectionProviderRef.current?.lastSelectedColumn}
                onCardClick={onCardClick}
              />
            );
          })}
          {createPortal(
            <DragOverlay adjustScale={false} dropAnimation={null}>
              {activeId ? <DragOverlayComponent activeId={activeId} columns={columns} /> : null}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </div>
    </BoardCardSelectionProvider>
  );
};
