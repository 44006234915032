import {
  ErrorPage,
  InfoCardInner,
  Link,
  LoadingPage,
  Modal,
  readableDate,
} from '@chocolate-soup-inc/cs-frontend-components';
import { useCallback, useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { getGiftRecipientType, getGiftEventType } from '../../../entities/gifts/utils';
import { StatusCell, TrackingLink } from '../../../entities/shipments/utils';
import { COMPANY_PATH, DETAILS_PATH, EMPLOYEES_PATH, SHIPMENTS_PATH, SHIPMENT_PATH } from '../../../routes/paths';
import { EmployeeModal } from '../EmployeeModal/EmployeeModal';

import styles from './EmployeeGifts.module.scss';
import { useFragmentOrFetchEmployee } from '../../../entities/employees/queries';
import { TGiftType, useQueryAllEmployeeAndDependantsGifts } from '../../../entities/gifts/queries';
import { TGiftWithShipmentFieldsFragment } from '../../../generated/graphql';

export const EmployeeGifts = () => {
  const navigate = useNavigate();
  const { companyId, employeeId } = useParams();

  const {
    data: employee,
    error,
    loading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: companyId as string,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return `${fullName} and Dependants Gifts`;
    else return 'Employee and Dependants Dependants';
  }, [employee?.fullName]);

  const {
    data: gifts,
    error: giftsError,
    loading: giftsLoading,
  } = useQueryAllEmployeeAndDependantsGifts({
    employeeId: employeeId as string,
    companyId: companyId as string,
  });

  const goToShipment = useCallback(
    (gift?: (typeof gifts)[number]) => {
      if (gift?.shipment) {
        navigate(
          generatePath(`${SHIPMENTS_PATH}/${COMPANY_PATH}/${SHIPMENT_PATH}/${DETAILS_PATH}`, {
            companyId: gift.companyId,
            shipmentId: gift.shipment.id,
          }),
          {
            state: {
              backPath: window.location.pathname,
              closePath: generatePath(EMPLOYEES_PATH),
            },
          },
        );
      }
    },
    [navigate],
  );

  if (loading || giftsLoading || !employee) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error || giftsError) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <ErrorPage error={error || giftsError} />
      </Modal>
    );
  }

  return (
    <EmployeeModal employee={employee} headline={title}>
      <div className={styles.employeeGifts}>
        {gifts?.map((gift, index) => (
          <InfoCardInner<TGiftType<TGiftWithShipmentFieldsFragment>>
            key={gift.id}
            data={gift}
            sections={[
              {
                title: `Gift #${gifts.length - index}`,
                columns: [
                  {
                    label: 'Recipient Name',
                    render: (data) => data?.recipient?.fullName,
                  },
                  {
                    label: 'Relationship',
                    render: () => {
                      return <span>{getGiftRecipientType(gift)}</span>;
                    },
                  },
                  {
                    label: 'Event Type',
                    render: () => {
                      return <span>{getGiftEventType(gift)}</span>;
                    },
                  },
                  {
                    label: 'Event Date',
                    render: (data) => readableDate(data?.eventDate),
                  },
                ],
              },
              {
                columns: [
                  {
                    label: 'Status',
                    render: () => StatusCell(gift.shipment || undefined),
                  },
                  {
                    label: 'Shipping Date',
                    render: (data) =>
                      readableDate(data?.shipment?.actualShippingDate || data?.shipment?.shippingDate || undefined),
                  },
                  {
                    label: 'Tracking Number',
                    render: () => (gift.shipment ? TrackingLink(gift.shipment) : null),
                  },
                  {
                    label: 'Shipment',
                    render: () => {
                      if (gift.shipment == null) {
                        return null;
                      } else {
                        return <Link onClick={() => goToShipment(gift)} label='See Shipment Details' />;
                      }
                    },
                  },
                ],
              },
            ]}
          />
        ))}
      </div>
    </EmployeeModal>
  );
};
