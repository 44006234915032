import React from 'react';
import { Cell } from '@tanstack/react-table';
import { Loading } from '@chocolate-soup-inc/cs-frontend-components';
import { TCompanyType } from '../../entities/companies/queries';
import { useCompanyQueryById } from '../../entities/employees/queries';
import clsx from 'clsx';
import tableStyles from '../tablePage/TablePage.module.scss';

type TConfiguredCellProps = {
  cell: Cell<TCompanyType, unknown>;
};

export const CustomEmployeeCountCell = React.memo((props: TConfiguredCellProps) => {
  const { cell } = props;
  const { data, loading } = useCompanyQueryById(cell?.row?.id);
  if (loading) return <Loading className={clsx(tableStyles.tableLoading)} />;
  return (
    <div className={clsx(tableStyles.tableSpaceTertiary)}>
      <span>{data?.listCompanyEmployees?.items.length}</span>
    </div>
  );
});

CustomEmployeeCountCell.displayName = 'CustomEmployeeCountCell';
