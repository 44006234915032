import { Modal, NavigationTabs } from '@chocolate-soup-inc/cs-frontend-components';
import { ReactNode, useCallback } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { COMPANIES_PATH, COMPANY_PATH, DETAILS_PATH, USERS_PATH } from '../../../routes/paths';

import styles from './CompanyModal.module.scss';
import { useFragmentOrFetchCompany } from '../../../entities/companies/queries';

export type TCompanyModalProps = {
  children: ReactNode;
  headline?: string;
};

export const CompanyModal = (props: TCompanyModalProps) => {
  const { children, headline } = props;
  const navigate = useNavigate();
  const { companyId } = useParams();

  const closeModal = useCallback(() => {
    navigate(generatePath(COMPANIES_PATH));
  }, [navigate]);

  const { data: company } = useFragmentOrFetchCompany({
    id: companyId as string,
  });

  return (
    <Modal closeModal={closeModal} headline={headline} open={true} size='large'>
      <NavigationTabs
        options={[
          {
            leadingIcon: 'info',
            label: 'Details',
            path: generatePath(`${COMPANIES_PATH}/${COMPANY_PATH}/${DETAILS_PATH}`, {
              companyId: companyId as string,
            }),
          },
          {
            disabled: !company?.fullyConfigured,
            leadingIcon: 'group',
            label: 'Users',
            path: generatePath(`${COMPANIES_PATH}/${COMPANY_PATH}/${USERS_PATH}`, {
              companyId: companyId as string,
            }),
          },
        ]}
      />
      <div className={styles.tabContent}>{children}</div>
    </Modal>
  );
};
