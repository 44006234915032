import { Active, Over, useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { CSSProperties, MouseEvent, ReactNode, useCallback, useMemo } from 'react';

import styles from './Board.module.scss';
import { useBoardCardSelectionContext } from './BoardCardSelectionContext';
import { calculateDisabled, calculateId, TCalculateDisabledProps, TCalculateIdProps } from './utils';

export type TDraggableCardRenderOptions = {
  active?: Active | null;
  isDragging?: boolean;
  isSelected?: boolean;
  measureElement?: (el: HTMLDivElement | null) => void;
  over?: Over | null;
  overlay?: boolean;
  rowIndexAttribute?: any;
  rowIndexAttributeName?: string;
};

export type TDraggableCardProps<T extends Record<string, any> = Record<string, any>> = {
  data: T;
  disabled?: boolean | ((data: T) => boolean);
  id: string | number | ((data?: T) => string | number);
  measureElement?: (el: HTMLDivElement | null) => void;
  onCardClick?: (card: T) => void;
  render: (data: T, opts?: TDraggableCardRenderOptions) => ReactNode;
  rowIndexAttribute?: any;
  rowIndexAttributeName?: string;
  style?: CSSProperties;
};

export const DraggableCard = <T extends Record<string, any> = Record<string, any>>(props: TDraggableCardProps<T>) => {
  const { measureElement, data, render, rowIndexAttribute, rowIndexAttributeName, style = {}, onCardClick } = props;

  const { onCardMouseDown, selectedCards } = useBoardCardSelectionContext();

  const disabled = useMemo(() => {
    return calculateDisabled<T>(props as TCalculateDisabledProps<T>) || false;
  }, [props]);

  const id = useMemo(() => {
    return calculateId<T>(props as TCalculateIdProps<T>);
  }, [props]);

  const { active, attributes, listeners, setNodeRef, over } = useDraggable({
    data,
    disabled,
    id,
  });

  const isSelected = useMemo(() => {
    return selectedCards.includes(id);
  }, [id, selectedCards]);

  const isDragging = useMemo(() => {
    return active != null && isSelected;
  }, [active, isSelected]);

  const children = useMemo(() => {
    return render(data, {
      active,
      isSelected,
      isDragging,
      measureElement,
      over,
      rowIndexAttribute,
      rowIndexAttributeName,
    });
  }, [active, data, isDragging, isSelected, measureElement, over, render, rowIndexAttribute, rowIndexAttributeName]);

  const onMouseDown = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      // if (!disabled) onCardMouseDown(e, id);
      onCardMouseDown(e, id);
    },
    [id, onCardMouseDown],
  );

  return (
    <div
      className={clsx(styles.draggableCard, isDragging && styles.isDragging)}
      ref={setNodeRef}
      style={style}
      {...listeners}
      {...attributes}
      onClick={
        onCardClick
          ? (e) => {
              if ((!e.shiftKey && !e.ctrlKey && !e.metaKey) || disabled) {
                onCardClick(data);
              }
            }
          : undefined
      }
    >
      <div className={styles.draggableCardInner} onMouseDown={onMouseDown}>
        {children}
      </div>
    </div>
  );
};
