import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './SplitButton.module.scss';
import { TGiftStatuses } from '../../generated/graphql';
import { CommonButton } from '@chocolate-soup-inc/cs-frontend-components';

type TSplitButtonProps = {
  options: { label: string; value: any }[];
  dropDownAction: Dispatch<SetStateAction<TGiftStatuses | undefined>> | (() => any);
  buttonAction: () => any;
  loading: boolean | undefined;
  disabled: boolean | undefined;
};

const SplitButton = (props: TSplitButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [valueName, setValueName] = useState('');
  const { options, dropDownAction, buttonAction, loading, disabled } = props;

  useEffect(() => {
    setValueName(options[options.length - 1]?.label);
    dropDownAction(options[options.length - 1]?.value as TGiftStatuses);
  }, []); // eslint-disable-line

  return (
    <div className={styles.bySplitButtonDropdown}>
      <CommonButton
        label={`Move to ${valueName}`}
        className={options.length > 1 ? styles.byActionButton : styles.byActionButtonRounded}
        disabled={loading || disabled}
        loading={loading}
        onClick={buttonAction}
        variant='filled'
        type='submit'
      />
      {options && options?.length > 1 && (
        <button className={styles.byToggleButton} onClick={() => setIsOpen(!isOpen)}>
          &#9660;
        </button>
      )}
      {isOpen && (
        <div className={styles.byDropdownMenu}>
          {options?.map((e) => (
            <button
              key={e.label + e.value}
              className={styles.byDropdownItem}
              onClick={() => {
                setValueName(e.label);
                dropDownAction(e.value as TGiftStatuses);
                setIsOpen(false);
              }}
            >
              {e.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default SplitButton;
