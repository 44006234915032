import { getFragmentName } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { ErrorPage, ExtendedFAB, LoadingPage } from '@chocolate-soup-inc/cs-frontend-components';
import { DragEndEvent, UniqueIdentifier } from '@dnd-kit/core';
import _ from 'lodash';
import { quickScore } from 'quick-score';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { serializeError } from 'serialize-error';
import { Board } from '../../../components/board/Board';
import { TColumnProps } from '../../../components/board/Column';
import { TDraggableCardRenderOptions } from '../../../components/board/DraggableCard';
import { Filters, TFiltersProps } from '../../../components/filters/Filters';
import { cache } from '../../../config/apollo/cache';
import { GiftFieldsFragmentDoc, TGiftStatuses, useMoveGiftMutation } from '../../../generated/graphql';
import { COMPANY_PATH, GIFT_PATH } from '../../../routes/paths';
import { GiftCard } from '../GiftCard/GiftCard';

import styles from './GiftAssembling.module.scss';
import { MovingToDoneModalContent } from './MovingToDoneModalContent';
import { FloatingActions } from '../../../components/floatingActions/FloatingActions';
import {
  TGroupedGiftType,
  useQueryAllNewHireAssemblingGifts,
  useQueryAllSubscriptionAssemblingGifts,
} from '../../../entities/gifts/queries';
import { printGiftLabels } from '../../../entities/gifts/shared';
import { useFilter } from '../../../contexts/filters';

export type TGiftAssemblingColumnDetails = {
  name: string;
  destinationColumns?: TGiftStatuses[];
  nextPhase?: TGiftStatuses;
  id: TGiftStatuses;
};

type TGiftAssemblingProps = {
  columnsDetails: TGiftAssemblingColumnDetails[];
  extraFilterFunction?: (gift: TGroupedGiftType) => boolean;
  extraFilters?: TFiltersProps['filters'];
  query: typeof useQueryAllSubscriptionAssemblingGifts | typeof useQueryAllNewHireAssemblingGifts;
  sortingFunction?: (a: TGroupedGiftType, b: TGroupedGiftType) => number;
};

export const GiftAssembling = (props: TGiftAssemblingProps) => {
  const { filtersPageMode } = useFilter();
  useEffect(() => {
    filtersPageMode();
  }, []); //eslint-disable-line

  const { columnsDetails, extraFilterFunction = () => true, extraFilters, query } = props;

  const { data: gifts, error, loading } = query();

  const companies = useMemo(() => {
    return _.uniqBy(_.compact(gifts.map((g) => g.company)), 'id');
  }, [gifts]);

  const racks = useMemo(() => {
    return _.uniq(_.compact(_.compact(gifts || []).map((g) => g.rack)));
  }, [gifts]);

  const navigate = useNavigate();
  const [companyFilter, setCompanyFilter] = useState<string>();
  const [recipientFilter, setRecipientFilter] = useState<string>();
  const [rackFilter, setRackFilter] = useState<string>();

  useEffect(() => {
    if (companies.find((c) => c.id === companyFilter) == null) {
      setCompanyFilter(undefined);
    }
  }, [companyFilter, companies]);

  useEffect(() => {
    if (racks.find((r) => r === rackFilter) == null) {
      setRackFilter(undefined);
    }
  }, [rackFilter, racks]);

  const filters = useMemo(() => {
    let filtersList: TFiltersProps['filters'] = [];

    if (companies.length > 0) {
      filtersList.push({
        includeEmptyOption: true,
        label: 'Company',
        name: 'companyId',
        onChange: (v) => {
          if (v == null) setCompanyFilter(undefined);
          else setCompanyFilter(v as string);
        },
        options: companies.map((c) => ({ label: c.name, value: c.id })),
        type: 'singleSelect',
        value: companyFilter,
      });
    }

    filtersList.push({
      label: 'Recipient',
      name: 'recipientName',
      onChange: (v) => {
        if (v == null) setRecipientFilter(undefined);
        else setRecipientFilter(v);
      },
      type: 'textInput',
      value: recipientFilter,
    });

    filtersList.push({
      disabled: racks == null || racks.length === 0,
      includeEmptyOption: true,
      label: 'Rack',
      name: 'rack',
      onChange: (v) => {
        if (v == null) setRackFilter(undefined);
        setRackFilter(v as string);
      },
      type: 'singleSelect',
      options: racks.map((r) => ({ label: r, value: r })),
      value: rackFilter,
    });

    if (extraFilters) {
      filtersList = filtersList.concat(extraFilters);
    }

    return filtersList;
  }, [companies, companyFilter, extraFilters, rackFilter, racks, recipientFilter]);

  const filteredGifts = useMemo(() => {
    return gifts?.filter((g) => {
      if (companyFilter != null && g.companyId !== companyFilter) {
        return false;
      }

      if (
        recipientFilter != null &&
        recipientFilter !== '' &&
        (g.recipient?.fullName == null || quickScore(g.recipient.fullName, recipientFilter) < 0.7)
      ) {
        return false;
      }

      if (rackFilter != null && g.rack !== rackFilter) return false;

      return extraFilterFunction(g);
    });
  }, [companyFilter, extraFilterFunction, gifts, rackFilter, recipientFilter]);

  const groupedByStatusGifts = useMemo(() => {
    return _.groupBy(filteredGifts, (gift) => gift.status);
  }, [filteredGifts]);

  // const sortedGroupedByStatusGifts = useMemo(() => {
  //   return Object.entries(groupedByStatusGifts).reduce((agg, [key, statusGifts]) => {
  //     agg[key] = statusGifts.sort(sortingFunction);
  //     return agg;
  //   }, {} as Record<string, TGiftWithGroupedGiftsAndReferenceDate[]>);
  // }, [groupedByStatusGifts, sortingFunction]);

  const renderSubscriptionGift = useCallback(
    (gift: TGroupedGiftType, opts?: TDraggableCardRenderOptions) => {
      return <GiftCard key={gift.id} filteredGifts={filteredGifts} gift={gift} options={opts} />;
    },
    [filteredGifts],
  );

  const columns: TColumnProps<TGroupedGiftType>[] = useMemo(() => {
    return columnsDetails.map((columnDetails) => {
      return {
        cards: (groupedByStatusGifts[columnDetails.id] || []).map((gift) => {
          return {
            data: gift,
            disabled: gift?.status === TGiftStatuses.Done,
            id: gift.id,
            render: renderSubscriptionGift,
          };
        }),
        data: {
          destinationColumns: columnDetails.destinationColumns,
        },
        id: columnDetails.id,
        name: columnDetails.name,
      };
    }, []);
  }, [columnsDetails, groupedByStatusGifts, renderSubscriptionGift]);

  const [moveGift] = useMoveGiftMutation();

  const updateGiftFragment = useCallback((gift: TGroupedGiftType, status: TGiftStatuses) => {
    cache.writeFragment({
      data: {
        ...gift,
        status,
        doneAt: status === TGiftStatuses.Done ? new Date().toISOString() : null,
        // This 2 is being used to know that it is temporary done.
        isDone: status === TGiftStatuses.Done ? 2 : 0,
      },
      id: cache.identify(gift),
      fragment: GiftFieldsFragmentDoc,
      fragmentName: getFragmentName(GiftFieldsFragmentDoc),
    });
  }, []);

  const [giftsGoingToDone, setGiftsGoingToDone] = useState<TGroupedGiftType[]>();

  const onDragEnd = useCallback(
    (event: DragEndEvent, selectedGifts: TGroupedGiftType[]) => {
      const droppedColumnId = event.over?.id as TGiftStatuses | undefined;
      const draggedGift = event.active.data.current as TGroupedGiftType | undefined;

      if (droppedColumnId === TGiftStatuses.Done) {
        const giftsWithNoAddress = selectedGifts.filter((g) => g.addressId == null || g.addressId === '');

        if (giftsWithNoAddress.length === 0) {
          for (const gift of selectedGifts) {
            updateGiftFragment(gift, droppedColumnId);
          }

          setGiftsGoingToDone(selectedGifts);
        } else {
          toast.error(
            `You are trying to move ${giftsWithNoAddress.length} gift${
              giftsWithNoAddress.length !== 1 ? 's' : ''
            } to done that have no address.`,
          );
        }
      } else if (draggedGift && droppedColumnId && draggedGift.status !== droppedColumnId) {
        for (const gift of selectedGifts) {
          updateGiftFragment(gift, droppedColumnId);

          moveGift({
            variables: {
              id: gift.id,
              companyId: gift.companyId,
              version: gift._version,
              input: {
                status: droppedColumnId,
              },
            },
          }).catch((error) => {
            console.error(serializeError(error));
            toast.error('An error occurred when trying to move the gift.');
            updateGiftFragment(gift, gift.status);
          });
        }
      }
    },
    [moveGift, updateGiftFragment],
  );

  const onCardClick = useCallback(
    (gift: TGroupedGiftType) => {
      navigate(
        generatePath(`${COMPANY_PATH}/${GIFT_PATH}`, {
          companyId: gift.companyId,
          giftId: gift.id,
        }),
      );
    },
    [navigate],
  );

  const onMoveToDoneCancel = useCallback(() => {
    if (giftsGoingToDone) {
      for (const gift of giftsGoingToDone) {
        updateGiftFragment(gift, gift.status);
      }

      setGiftsGoingToDone(undefined);
    }
  }, [giftsGoingToDone, updateGiftFragment]);

  const onMoveToDoneConfirm = useCallback(() => {
    setGiftsGoingToDone(undefined);
  }, []);

  const [selectedGifts, setSelectedGifts] = useState<TGroupedGiftType[]>([]);

  const onSelectedCardsChange = useCallback(
    (selectedCardsIds: UniqueIdentifier[]) => {
      if (filteredGifts != null) {
        setSelectedGifts(filteredGifts.filter((g) => selectedCardsIds.includes(g.id)));
      }
    },
    [filteredGifts],
  );

  const [printLoading, setPrintLoading] = useState<boolean>(false);

  const onPrintClick = useCallback(async () => {
    setPrintLoading(true);

    try {
      await printGiftLabels({
        gifts: selectedGifts,
      });
    } catch (error) {
      console.error(serializeError(error));
      toast.error('There was an error trying to print labels.');
    } finally {
      setPrintLoading(false);
    }
  }, [selectedGifts]);

  if (error) return <ErrorPage error={error} />;
  if (loading) return <LoadingPage />;

  return (
    <>
      {giftsGoingToDone && giftsGoingToDone.length > 0 && (
        <MovingToDoneModalContent
          allGifts={gifts || []}
          gifts={giftsGoingToDone}
          onCancel={onMoveToDoneCancel}
          onSuccess={onMoveToDoneConfirm}
        />
      )}
      <div className={styles.giftAssemblingPage}>
        <Filters filters={filters} />
        <Board<TGroupedGiftType>
          columns={columns}
          onDragEnd={onDragEnd}
          onCardClick={onCardClick}
          onSelectedCardsChange={onSelectedCardsChange}
        />
        {selectedGifts.length > 0 && (
          <FloatingActions>
            <ExtendedFAB
              label={`Print ${selectedGifts.length} Labels`}
              leadingIcon='print'
              loading={printLoading}
              onClick={onPrintClick}
              variant='tertiary'
            />
          </FloatingActions>
        )}
      </div>
    </>
  );
};
