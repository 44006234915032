import { Storage } from '@chocolate-soup-inc/cs-api-consumer-utils';

Storage.configure({
  level: 'public',
  customPrefix: {
    public: '',
  },
  AWSS3: {
    bucket: process.env.REACT_APP_IMPORT_EMPLOYEES_ROSTERS_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
  },
});
