import Joi from 'joi';

export const getUpdateShipmentExtraAttributesSchema = () => {
  return Joi.object()
    .unknown(false)
    .keys({
      rack: Joi.string().trim().empty(Joi.valid(null, '')),
      notes: Joi.string().trim().empty(Joi.valid(null, '')),
    });
};
