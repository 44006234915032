import { useEffect, useMemo } from 'react';
import {
  CompanyFieldsFragmentDoc,
  GetCompanyDocument,
  OnAnyCompanyChangedDocument,
  TCompanyFieldsFragment,
  TGetCompanyQuery,
  TGetCompanyQueryVariables,
  TListCompaniesQuery,
  TListCompaniesQueryVariables,
  TListCompanyFieldsFragment,
  TOnAnyCompanyChangedSubscription,
  TOnAnyCompanyChangedSubscriptionVariables,
  useGetCompanyLazyQuery,
  useListCompaniesLazyQuery,
} from '../../generated/graphql';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';
import { useMap } from '../shared/useMap';
import { useFragmentOrFetch } from '../shared/useFragmentOrFetch';
import { client } from '../../config/apollo/api';
import { onSubscriptionData } from '../../config/apollo/cache';
import { TConnectionItem, subscribe } from '@chocolate-soup-inc/cs-api-consumer-utils';
import { serializeError } from 'serialize-error';
import { useAllAddressesMap } from '../addresses/queries';

// SUBSCRIBE

const queryCompanyOnSubscriptionData = (
  data?: TOnAnyCompanyChangedSubscription,
  vars?: TOnAnyCompanyChangedSubscriptionVariables,
) => {
  const { id, _deleted } = data?.onAnyCompanyChanged || {};

  if (_deleted) {
    return onSubscriptionData(data as Record<string, any>, vars);
  } else if (id) {
    client
      .query<TGetCompanyQuery, TGetCompanyQueryVariables>({
        query: GetCompanyDocument,
        variables: {
          id,
        },
      })
      .then((response) => {
        const companyData = response?.data?.getCompany;

        if (companyData) {
          const subscriptionData = {
            onAnyCompanyChanged: companyData as TConnectionItem,
          };

          // CHANGED DATA FOR QUERIES THAT DEPEND ON NO VARIABLE (SINCE VARS SHOULD BE AN EMPTY OBJECT IN THIS SITUATION)
          onSubscriptionData(subscriptionData, vars);

          // CHANGED DATA FOR ADDRESS TOO!
          onSubscriptionData(
            {
              onAnyAddressChanged: companyData.address as TConnectionItem,
            },
            vars,
          );
        }
      })
      .catch((error) => {
        console.error('Error', serializeError(error));
      });
  }
};

let anyCompanyChangedSubscribed = false;

export const useSubscribeToCompanyChanged = () => {
  useEffect(() => {
    if (!anyCompanyChangedSubscribed) {
      subscribe({
        client,
        query: OnAnyCompanyChangedDocument,
        onSubscriptionData: (data, vars) => {
          return queryCompanyOnSubscriptionData(
            data as TOnAnyCompanyChangedSubscription,
            vars as TOnAnyCompanyChangedSubscriptionVariables,
          );
        },
        variables: {},
      });
    }

    anyCompanyChangedSubscribed = true;
  }, []);
};

// LISTS

export const useFullCompanies = <T extends TListCompanyFieldsFragment>(companies: T[]) => {
  const { data: addressesMap, error: addressesError, loading: addressesLoading } = useAllAddressesMap();

  const fullCompanies = useMemo(() => {
    return companies.map((c) => {
      return {
        ...c,
        address: addressesMap[`${process.env.REACT_APP_COMPANIES_TABLE_NAME}|${c.id}`],
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressesMap, JSON.stringify(companies)]);

  return {
    data: fullCompanies,
    error: addressesError,
    loading: addressesLoading,
  };
};

export type TCompanyType<T extends TListCompanyFieldsFragment = TListCompanyFieldsFragment> = ReturnType<
  typeof useFullCompanies<T>
>['data'][number];

export const useQueryAllCompanies = () => {
  useSubscribeToCompanyChanged();

  const { data, error, loading } = useQueryAll<TListCompaniesQuery, TListCompaniesQueryVariables>({
    useQuery: useListCompaniesLazyQuery,
    variables: {},
  });

  const {
    data: fullCompanies,
    error: fullError,
    loading: fullLoading,
  } = useFullCompanies(_.compact(data?.listCompanies?.items));

  return {
    data: fullCompanies,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useAllCompaniesMap = () => {
  const { data, error, loading } = useQueryAllCompanies();

  const companiesMap = useMap<TListCompanyFieldsFragment>(data);

  return {
    data: companiesMap,
    error,
    loading: loading || companiesMap == null,
  };
};

// ITEMS

export const useFragmentOrFetchCompany = (variables: TGetCompanyQueryVariables) => {
  useSubscribeToCompanyChanged();

  const { data, error, loading } = useFragmentOrFetch<
    TCompanyFieldsFragment,
    TGetCompanyQuery,
    TGetCompanyQueryVariables
  >({
    fragmentDoc: CompanyFieldsFragmentDoc,
    useLazyQuery: useGetCompanyLazyQuery,
    variables,
    __typename: 'Company',
  });

  const { data: companies, error: fullError, loading: fullLoading } = useFullCompanies(_.compact([data]));

  return {
    data: companies[0],
    error: error || fullError,
    loading: loading || fullLoading,
  };
};
