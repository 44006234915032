import {
  DatePicker,
  Select,
  TDatePickerProps,
  TextField,
  TSelectValue,
  TSingleSelectProps,
  TTextFieldInputProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';
import styles from './Filters.module.scss';
import { useFilter } from '../../contexts/filters';
import { useEffect } from 'react';

type TSelectFilterSharedProps = {
  disabled?: boolean;
  includeEmptyOption?: boolean;
  label?: string;
  name: string;
  className?: string;
};

type TSingleSelectFilterProps = TSelectFilterSharedProps & {
  onChange: TSingleSelectProps['onChange'];
  options: TSingleSelectProps['options'];
  value: TSingleSelectProps['value'];
};

export const SingleSelectFilter = (props: TSingleSelectFilterProps) => {
  const {
    className,
    disabled,
    includeEmptyOption = true,
    label,
    name = `${label}-filter`,
    onChange,
    options,
    value,
  } = props;

  return (
    <div className={clsx(className, styles.filter)}>
      <Select
        className={clsx(styles.filterField)}
        disabled={disabled}
        includeEmptyOption={includeEmptyOption}
        label={label}
        multiple={false}
        name={name}
        onChange={onChange}
        options={options}
        optionsPlacement='bottom'
        value={value}
        variant='outlined'
      />
    </div>
  );
};

export type TTextFilterProps = Partial<
  Pick<TTextFieldInputProps, 'className' | 'disabled' | 'label' | 'leadingIcon' | 'name' | 'onChange' | 'value'>
>;

export const TextFilter = (props: TTextFilterProps) => {
  const { className, disabled, label, leadingIcon, name = `${label}-filter`, onChange, value } = props;

  return (
    <div className={clsx(className, styles.filter)}>
      <TextField
        className={clsx(styles.filterField)}
        disabled={disabled}
        label={label}
        leadingIcon={leadingIcon}
        multiline={false}
        name={name}
        onChange={onChange}
        value={value}
        variant='outlined'
      />
    </div>
  );
};

export const DateFilter = (props: TDatePickerProps) => {
  const { className, disabled, label, name = `${label}-filter`, onChange, value } = props;

  return (
    <div className={clsx(className, styles.filter)}>
      <DatePicker
        className={clsx(styles.dateField)}
        disabled={disabled}
        label={label}
        name={name}
        onChange={onChange}
        value={value}
        variant='outlined'
      />
    </div>
  );
};

export type TFilterProps =
  | (TSingleSelectFilterProps & {
      type: 'singleSelect';
    })
  | (TTextFilterProps & {
      type: 'textInput';
    })
  | (TDatePickerProps & { type: 'datePicker' });

export type TFiltersProps = {
  filters: TFilterProps[];
};

export const Filters = (props: TFiltersProps) => {
  const { filters } = props;
  const { setFilters, setFiltersUpdaters, onFilterChange } = useFilter();
  /* TODO: (tl:dr;) We can't manage these states in the Filter component due to its own mutations in the useMemo that's consumed up here in ${props},
        it must be done in the invoker so the context states aren't overwritten by these mutations.
        The useEffect which tells the filters context whether it resets them and whether it shows them or not must run just once,
        that functionality is responsibility of each invocation of the context in each component,
        just like the separate-page-modals already handle them, and like the implementation that's proven here to work. */
  useEffect(() => {
    let allowedFilters: string[] = [];
    let allowedFiltersUpdaters: Record<string, any> = [];
    filters.forEach((filter) => {
      if (filter.name && filter.onChange) {
        allowedFilters = [...allowedFilters, filter.name];
        allowedFiltersUpdaters = { ...allowedFiltersUpdaters, [filter.name]: filter.onChange };
      }
    });
    setFilters(allowedFilters);
    setFiltersUpdaters(allowedFiltersUpdaters);
  }, [filters, setFilters, setFiltersUpdaters]);

  return (
    <div className={styles.filters}>
      {filters.map((filter) => {
        const newOnChange = (v?: TSelectValue | string) => {
          if (filter.name) onFilterChange(filter.name, (v as string) ?? '');
        };
        if (filter.type === 'singleSelect')
          return <SingleSelectFilter key={filter.name || filter.label} {...filter} onChange={newOnChange} />;
        if (filter.type === 'textInput')
          return <TextFilter key={filter.name || filter.label} {...filter} onChange={newOnChange} />;
        if (filter.type === 'datePicker')
          return <DateFilter key={filter.name || filter.label} {...filter} onChange={newOnChange} />;
      })}
    </div>
  );
};
