import { useCallback, useMemo } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import styles from './Uploader.module.scss';
import { CommonButton, Icon, TMaterialIcons } from '@chocolate-soup-inc/cs-frontend-components';
import clsx from 'clsx';

export type TOnDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => void;

type TUploderProps = {
  disabled?: boolean;
  file?: File;
  onDrop?: TOnDrop;
};

export const getFileIcon = (file: File): TMaterialIcons | undefined => {
  switch (file.type) {
    case 'audio/aac':
    case 'application/x-cdf':
    case 'audio/midi':
    case 'audio/x-midi':
    case 'audio/mpeg':
    case 'audio/ogg':
    case 'audio/opus':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/3gpp':
    case 'audio/3gpp2':
      // AUDIO
      return 'audio_file';
    case 'application/pdf':
      // PDF
      return 'picture_as_pdf';
    case 'application/x-abiword':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.oasis.opendocument.text':
    case 'text/plain':
      // WORD
      return 'description';
    case 'text/csv':
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      // EXCEL
      return 'description';
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      // POWERPOINT
      return 'description';
    case 'application/x-freearc':
    case 'application/x-bzip':
    case 'application/x-bzip2':
    case 'application/gzip':
    case 'application/vnd.rar':
    case 'application/x-tar':
    case 'application/zip':
    case 'application/x-7z-compressed':
      // ARCHIVE
      return 'inventory_2';
    case 'image/avif':
    case 'image/bmp':
    case 'image/gif':
    case 'image/vnd.microsoft.icon':
    case 'image/jpeg':
    case 'image/png':
    case 'image/svg+xml':
    case 'image/tiff':
    case 'image/webp':
      // IMAGE
      return 'image';
    case 'video/x-msvideo':
    case 'video/mp4':
    case 'video/mpeg':
    case 'video/ogg':
    case 'video/mp2t':
    case 'video/webm':
    case 'video/3gpp':
    case 'video/3gpp2':
      // VIDEO
      return 'video_file';
    case 'application/vnd.amazon.ebook':
    case 'application/epub+zip':
      // E-BOOK AND PUBLICATIONS
      return 'menu_book';
    case 'application/octet-stream':
    case 'application/vnd.apple.installer+xml':
      // BINARY
      return 'view_module';
    case 'application/x-csh':
    case 'text/html':
    case 'application/java-archive':
    case 'text/javascript':
    case 'application/x-httpd-php':
    case 'application/x-sh':
    case 'application/xhtml+xml':
      // SCRIPT
      return 'code_blocks';
    case 'application/vnd.ms-fontobject':
    case 'font/otf':
    case 'font/ttf':
    case 'font/woff':
    case 'font/woff2':
      // FONT
      return 'text_fields';
    case 'text/calendar':
      // CALENDAR
      return 'calendar_month';
    case 'application/json':
    case 'application/ld+json':
    case 'application/xml':
    case 'text/xml':
    case 'application/atom+xml':
      // DATA
      return 'data_object';
    case 'application/ogg':
    case 'application/rtf':
    case 'application/vnd.visio':
    case 'application/vnd.mozilla.xul+xml':
      // OTHER
      return undefined;
    default:
      return undefined;
  }
};

export const Uploader = (props: TUploderProps) => {
  const { disabled = false, file, onDrop: propsOnDrop } = props;

  const onDrop: TOnDrop = useCallback(
    (acceptedFiles, fileRejections, event) => {
      if (propsOnDrop) propsOnDrop(acceptedFiles, fileRejections, event);
    },
    [propsOnDrop],
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      'text/csv': ['.csv'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
    disabled,
    maxFiles: 1,
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const fileIcon = useMemo(() => {
    if (file) return getFileIcon(file);
  }, [file]);

  return (
    <div className={clsx(styles.uploader, isDragActive && styles.isDragActive)} {...getRootProps()}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <div className={styles.uploaderContent}>
          <p>Drag {"'n'"} drop some files here, or</p>
          <CommonButton disabled={disabled} label='Click to select files' onClick={open} variant='elevated' />
          {file && (
            <div className={styles.selectedFile}>
              {fileIcon && <Icon icon={fileIcon} />}
              <p>{file.name}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
