import { useMemo } from 'react';
import {
  OnAnyDependantChangedDocument,
  TBasicDependantFieldsFragment,
  TListDependantsQuery,
  TListDependantsQueryVariables,
  TListEmployeeDependantsQuery,
  TListEmployeeDependantsQueryVariables,
  TOnAnyDependantChangedSubscriptionVariables,
  useListDependantsLazyQuery,
  useListEmployeeDependantsLazyQuery,
} from '../../generated/graphql';
import { useQueryAll } from '../shared/useQueryAll';
import _ from 'lodash';
import { useAllEmployeesMap } from '../employees/queries';
import { useMap } from '../shared/useMap';

// LIST

export const useFullDependants = <T extends TBasicDependantFieldsFragment>(dependants: T[]) => {
  const { data, error, loading } = useAllEmployeesMap();

  const fullDependants = useMemo(() => {
    return dependants.map((d) => {
      return {
        ...d,
        employee: d.employeeId ? data[d.employeeId] : undefined,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, JSON.stringify(dependants)]);

  return {
    data: fullDependants,
    error,
    loading,
  };
};

export type TDependantType<T extends TBasicDependantFieldsFragment = TBasicDependantFieldsFragment> = ReturnType<
  typeof useFullDependants<T>
>['data'][number];

export const useQueryAllEmployeeDependants = (variables: TListEmployeeDependantsQueryVariables) => {
  const { data, error, loading } = useQueryAll<
    TListEmployeeDependantsQuery,
    TListEmployeeDependantsQueryVariables,
    TOnAnyDependantChangedSubscriptionVariables
  >({
    useQuery: useListEmployeeDependantsLazyQuery,
    subscriptionDoc: OnAnyDependantChangedDocument,
    subscriptionVariables: {},
    variables,
  });

  const {
    data: dependants,
    error: fullError,
    loading: fullLoading,
  } = useFullDependants(_.compact(data?.listEmployeeDependants?.items));

  return {
    data: dependants,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useQueryAllDependants = () => {
  const { data, error, loading } = useQueryAll<
    TListDependantsQuery,
    TListDependantsQueryVariables,
    TOnAnyDependantChangedSubscriptionVariables
  >({
    useQuery: useListDependantsLazyQuery,
    subscriptionDoc: OnAnyDependantChangedDocument,
    subscriptionVariables: {},
    variables: {},
  });

  const {
    data: dependants,
    error: fullError,
    loading: fullLoading,
  } = useFullDependants(_.compact(data?.listDependants?.items));

  return {
    data: dependants,
    error: error || fullError,
    loading: loading || fullLoading,
  };
};

export const useAllDependantsMap = () => {
  const { data, error, loading } = useQueryAllDependants();

  const dependantsMap = useMap<(typeof data)[number]>(data);

  return {
    data: dependantsMap,
    error,
    loading: loading || dependantsMap == null,
  };
};
