export type TCalculateIdProps<T> = {
  data?: T;
  id: string | number | ((data?: T) => string | number);
};

export const calculateId = <T extends Record<string, any> = Record<string, any>>(props: TCalculateIdProps<T>) => {
  const { id, data } = props;
  return typeof id === 'function' ? id(data) : id;
};

export type TCalculateDisabledProps<T> = {
  data?: T;
  disabled?: boolean | ((data?: T) => boolean);
};

export const calculateDisabled = <T extends Record<string, any> = Record<string, any>>(
  props: TCalculateDisabledProps<T>,
) => {
  const { disabled, data } = props;
  return typeof disabled === 'function' ? disabled(data) : disabled;
};
