import { TControlledFormComponentProps } from '@chocolate-soup-inc/cs-frontend-components/lib/esm/components/Form/types/ControlledFormField';
import { TCreateUserMutationInput } from '../../generated/graphql';

export const getUserFormFields = () => {
  const formFields: TControlledFormComponentProps<TCreateUserMutationInput>[] = [
    {
      name: 'name',
      label: 'Name',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'email',
      label: 'Email',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'title',
      label: 'Title',
      type: 'textField',
      inputOptions: {
        multiline: false,
        autoComplete: 'off',
      },
    },
    {
      name: 'type',
      label: 'User Type',
      type: 'select',
      inputOptions: {
        multiple: false,
        options: [
          {
            label: 'Administrator',
            value: 'admin',
          },
          {
            label: 'Team Leader',
            value: 'teamLeader',
          },
        ],
        variant: 'outlined',
      },
    },
  ];

  return formFields;
};
