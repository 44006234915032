import { CountryAvatar, getCountryName } from '@chocolate-soup-inc/cs-frontend-components';
import { ForwardedRef, forwardRef, useMemo } from 'react';
import styles from './CountryName.module.scss';

type TCountryNameProps = {
  forwardedRef?: ForwardedRef<HTMLDivElement>;
  name?: string;
};

export const CountryNameInner = (props: TCountryNameProps) => {
  const { forwardedRef, name } = props;

  const countryName = useMemo(() => {
    if (name) {
      return getCountryName(name);
    } else {
      return undefined;
    }
  }, [name]);

  if (name == null) return null;

  return (
    <div className={styles.countryName} ref={forwardedRef}>
      <CountryAvatar elevated={true} size='medium' code={name} />
      <span>{countryName}</span>
    </div>
  );
};

CountryNameInner.displayName = 'CountryNameInner';

export const CountryName = forwardRef<HTMLDivElement, Omit<TCountryNameProps, 'forwardedRef'>>((props, ref) => {
  return <CountryNameInner {...props} forwardedRef={ref} />;
});

CountryName.displayName = 'CountryName';
