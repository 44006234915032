import { useCallback } from 'react';
import { useAddGiftsToShipmentMutation, useCreateShipmentWithGiftsMutation } from '../../generated/graphql';
import { onGiftSubscriptionData } from './queries';

export const useCreateShipmentWithGifts = () => {
  const [createShipmentWithGifts, data] = useCreateShipmentWithGiftsMutation();

  const createShipmentWithGiftsAndUpdateListQueries: typeof createShipmentWithGifts = useCallback(
    (options) => {
      return createShipmentWithGifts(options).then((response) => {
        response.data?.createShipmentWithGifts?.gifts?.items.map((g) => {
          if (g)
            onGiftSubscriptionData(
              {
                onAnyGiftChanged: g,
              },
              {},
            );
        });

        return response;
      });
    },
    [createShipmentWithGifts],
  );

  return [createShipmentWithGiftsAndUpdateListQueries, data] as ReturnType<typeof useCreateShipmentWithGiftsMutation>;
};

export const useAddGiftsToShipment = () => {
  const [addGiftsToShipment, data] = useAddGiftsToShipmentMutation();

  const addGiftsToShipmentAndUpdateListQueries: typeof addGiftsToShipment = useCallback(
    (options) => {
      return addGiftsToShipment(options).then((response) => {
        response.data?.addGiftsToShipment?.gifts?.items.map((g) => {
          if (g)
            onGiftSubscriptionData(
              {
                onAnyGiftChanged: g,
              },
              {},
            );
        });

        return response;
      });
    },
    [addGiftsToShipment],
  );

  return [addGiftsToShipmentAndUpdateListQueries, data] as ReturnType<typeof useAddGiftsToShipmentMutation>;
};
