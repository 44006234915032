import {
  Chip,
  IconButton,
  Modal,
  NavigationTabs,
  TNavigationTabLinkProps,
} from '@chocolate-soup-inc/cs-frontend-components';
import _ from 'lodash';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { TDeliveryMethod } from '../../../generated/graphql';
import {
  COMPANY_PATH,
  DEPENDANTS_PATH,
  EMPLOYEES_PATH,
  EMPLOYEE_ACCOUNT_PATH,
  EMPLOYEE_GIFTS_PATH,
  EMPLOYEE_PATH,
} from '../../../routes/paths';

import styles from './EmployeeModal.module.scss';
import { TEmployeeType } from '../../../entities/employees/queries';
import { useFilter } from '../../../contexts/filters';

type TEmployeeModalProps = {
  children: ReactNode;
  employee?: TEmployeeType;
  headline?: string;
};

export const EmployeeModal = (props: TEmployeeModalProps) => {
  const { filtersModalMode } = useFilter();
  useEffect(() => {
    filtersModalMode();
  }, []); // eslint-disable-line

  const { children, employee, headline } = props;

  const navigate = useNavigate();

  const closeModal = useCallback(() => {
    navigate(generatePath(EMPLOYEES_PATH));
  }, [navigate]);

  const options: TNavigationTabLinkProps[] = useMemo(() => {
    const opts: TNavigationTabLinkProps[] = [];

    if (employee?.id) {
      opts.push({
        label: 'Account',
        path: generatePath(`${EMPLOYEES_PATH}/${COMPANY_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_ACCOUNT_PATH}`, {
          employeeId: employee.id,
          companyId: employee.companyId,
        }),
      });

      if (
        employee?.company?.petBirthdayActivated ||
        employee?.company?.childBirthdayActivated ||
        employee?.company?.significantOtherBirthdayActivated
      ) {
        opts.push({
          label: 'Dependants',
          path: generatePath(`${EMPLOYEES_PATH}/${COMPANY_PATH}/${EMPLOYEE_PATH}/${DEPENDANTS_PATH}`, {
            employeeId: employee.id,
            companyId: employee.companyId,
          }),
        });
      }

      opts.push({
        label: 'Gifts',
        path: generatePath(`${EMPLOYEES_PATH}/${COMPANY_PATH}/${EMPLOYEE_PATH}/${EMPLOYEE_GIFTS_PATH}`, {
          employeeId: employee.id,
          companyId: employee.companyId,
        }),
      });
    }

    return opts;
  }, [
    employee?.company?.childBirthdayActivated,
    employee?.company?.petBirthdayActivated,
    employee?.company?.significantOtherBirthdayActivated,
    employee?.companyId,
    employee?.id,
  ]);

  return (
    <Modal contentClassName={styles.modal} closeModal={closeModal}>
      <div className={styles.employeeModal}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleContainer}>
            {headline && <h1 className={styles.title}>{headline}</h1>}
            <div className={styles.chipsContainer}>
              {_.isEmpty(employee?.address) && employee?.company?.deliveryMethod === TDeliveryMethod.Home && (
                <Chip
                  className={styles.errorChip}
                  label='Missing address'
                  readonly={true}
                  selected={true}
                  variant='suggestion'
                />
              )}
              {(employee?.hireDate == null || employee?.hireDate === '') && (
                <Chip
                  className={styles.errorChip}
                  label='Missing hire date'
                  readonly={true}
                  selected={true}
                  variant='suggestion'
                />
              )}
              {(employee?.birthDate == null || employee?.birthDate === '') &&
                employee?.company?.employeeBirthdayActivated && (
                  <Chip
                    className={styles.errorChip}
                    label='Missing birth date'
                    readonly={true}
                    selected={true}
                    variant='suggestion'
                  />
                )}
            </div>
          </div>
          <IconButton icon='close' onClick={closeModal} variant='standard' />
        </div>

        <div className={styles.header}>
          <NavigationTabs
            hideOutlet={true}
            className={styles.tabs}
            contentClassName={styles.tabsContent}
            options={options}
          />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </Modal>
  );
};
