import { Icon, IconButton, randomId, useStateLayer } from '@chocolate-soup-inc/cs-frontend-components';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  useDraggable,
  useDroppable,
  useSensor,
} from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import clsx from 'clsx';
import _ from 'lodash';
import { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GiftCard } from '../GiftCard/GiftCard';

import styles from './SplittingShipments.module.scss';
import { TGroupedGiftType } from '../../../entities/gifts/queries';

type TDraggableGiftProps = {
  gift: TGroupedGiftType;
};

const DraggableGift = (props: TDraggableGiftProps) => {
  const { gift } = props;

  const { active, attributes, listeners, setNodeRef, over, isDragging } = useDraggable({
    id: gift.id,
  });

  return (
    <div
      className={clsx(styles.draggableGift, isDragging && styles.isDragging)}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      <GiftCard gift={gift} options={{ active, isDragging, over, overlay: true }} />
    </div>
  );
};

type TDroppableShipmentProps = {
  gifts: TGroupedGiftType[];
  giftsIds: string[];
  id: string;
  index: number;
  onRemoveShipment: (id: string) => void;
};

const DroppableShipment = (props: TDroppableShipmentProps) => {
  const { gifts, giftsIds, id, index, onRemoveShipment } = props;

  const currentGifts = useMemo(() => {
    return gifts.filter((g) => giftsIds.find((id) => id === g.id));
  }, [gifts, giftsIds]);

  const { active, isOver, setNodeRef } = useDroppable({
    id,
  });

  return (
    <div
      className={clsx(styles.droppableShipment, active && styles.isDragging, isOver && styles.isOver)}
      ref={setNodeRef}
    >
      <div className={styles.droppableShipmentHeader}>
        <h2 className={styles.droppableShipmentTitle}>Shipment {index}</h2>
        {index > 2 && (
          <IconButton
            className={styles.droppableShipmentIcon}
            icon='clear'
            onClick={() => onRemoveShipment(id)}
            variant='standard'
          />
        )}
      </div>
      {currentGifts.map((gift) => {
        return <DraggableGift gift={gift} key={gift.id} />;
      })}
    </div>
  );
};

type TSplittingShipmentsProps = {
  gifts: TGroupedGiftType[];
  onShipmentsChange?: (groupedGifts: Record<string, string[]>) => void;
};

export const SplittingShipments = (props: TSplittingShipmentsProps) => {
  const { gifts, onShipmentsChange } = props;

  const [activeId, setActiveId] = useState<string>();
  const [shipments, setShipments] = useState<string[]>([]);
  const [groupedGifts, setGroupedGifts] = useState<Record<string, string[]>>({});

  useEffect(() => {
    if (onShipmentsChange) onShipmentsChange(groupedGifts);
  }, [groupedGifts, onShipmentsChange]);

  const draggingGift = useMemo(() => {
    if (activeId) {
      return gifts.find((g) => g.id === activeId);
    } else {
      return undefined;
    }
  }, [activeId, gifts]);

  useEffect(() => {
    const shipment1 = randomId();
    const shipment2 = randomId();
    const newShipments = [shipment1, shipment2];
    setShipments(newShipments);
    setGroupedGifts(
      _.chunk(gifts, Math.ceil(gifts.length / newShipments.length)).reduce((agg, chunkGifts, index) => {
        agg[newShipments[index]] = chunkGifts.map((g) => g.id);

        return agg;
      }, {} as Record<string, string[]>),
    );
  }, [gifts]);

  const onAddShipment = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.blur();

    setShipments((shipments) => {
      const newShipment = randomId();
      return [...shipments, newShipment];
    });
  }, []);

  const onRemoveShipment = useCallback(
    (id: string) => {
      setGroupedGifts((groupedGifts) => {
        const deletedShipmentGiftIds = groupedGifts[id];
        delete groupedGifts[id];
        if (deletedShipmentGiftIds) {
          const targetShipmentId = shipments[0];
          groupedGifts[targetShipmentId] = groupedGifts[targetShipmentId].concat(deletedShipmentGiftIds);
        }

        return _.cloneDeep(groupedGifts);
      });

      setShipments((shipments) => {
        const index = shipments.findIndex((shipmentId) => shipmentId === id);
        if (index > -1) {
          shipments.splice(index, 1);
        }

        return _.cloneDeep(shipments);
      });
    },
    [shipments],
  );

  const onDragEnd = useCallback((e: DragEndEvent) => {
    const droppedColumn = e.over?.id;

    if (droppedColumn && e.active.id) {
      setGroupedGifts((groupedGifts) => {
        const newGroupedGifts = _.cloneDeep(groupedGifts || {});
        for (const [key, ids] of Object.entries(groupedGifts)) {
          const index = ids.findIndex((id) => id === e.active.id);

          if (index > -1) {
            newGroupedGifts[key].splice(index, 1);
          }
        }

        newGroupedGifts[droppedColumn] = (newGroupedGifts[droppedColumn] || []).concat([e.active.id as string]);

        return newGroupedGifts;
      });
    }
    setActiveId(undefined);
  }, []);

  const onDragStart = useCallback((e: DragStartEvent) => {
    setActiveId(e.active.id as string);
  }, []);

  const pointerSensor = useSensor(MouseSensor);

  const { eventHandlers, stateLayer } = useStateLayer({});

  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      onDragEnd={onDragEnd}
      onDragStart={onDragStart}
      sensors={[pointerSensor]}
      collisionDetection={pointerWithin}
    >
      <div className={styles.splittingShipments}>
        {shipments.map((id, index) => {
          return (
            <DroppableShipment
              gifts={gifts}
              giftsIds={groupedGifts[id] || []}
              id={id}
              index={index + 1}
              key={id}
              onRemoveShipment={onRemoveShipment}
            />
          );
        })}
        <button className={styles.addShipmentButton} onClick={onAddShipment} {...eventHandlers}>
          {stateLayer}
          <Icon icon='add' />
          Add Shipment
        </button>
      </div>
      <DragOverlay adjustScale={false} dropAnimation={null}>
        {draggingGift ? <GiftCard gift={draggingGift} options={{ overlay: true }} /> : null}
      </DragOverlay>
    </DndContext>
  );
};
