import {
  DocumentNode,
  FetchPolicy,
  LazyQueryResultTuple,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { subscribe } from '../../config/apollo/cache';

type TUseQuerAllProps<TQuery, TVariables extends OperationVariables, TSubscriptionVariables> = {
  fetchPolicy?: FetchPolicy;
  WatchQueryFetchPolicy?: FetchPolicy;
  subscriptionDoc?: DocumentNode | TypedDocumentNode;
  subscriptionVariables?: TSubscriptionVariables;
  useQuery: (baseOptions: QueryHookOptions<TQuery, TVariables>) => LazyQueryResultTuple<TQuery, TVariables>;
  variables?: TVariables;
};

export const useQueryAll = <TQuery, TVariables extends OperationVariables, TSubscriptionVariables = undefined>(
  props: TUseQuerAllProps<TQuery, TVariables, TSubscriptionVariables>,
) => {
  const { fetchPolicy = 'cache-first', subscriptionDoc, subscriptionVariables, useQuery, variables } = props;

  const [fetchData, { data, error, fetchMore, loading }] = useQuery({
    variables,
    fetchPolicy,
  });

  const nextToken = useMemo(() => {
    if (data && typeof data === 'object') {
      for (const value of Object.values(data)) {
        if (value && typeof value === 'object' && value.nextToken != null) {
          return value.nextToken;
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (data == null) {
      fetchData();
    }
  }, [data, fetchData]);

  useEffect(() => {
    if (nextToken) {
      fetchMore({
        variables: {
          nextToken,
          ...(variables || {}),
        },
      });
    }
  }, [fetchMore, nextToken, variables]);

  useEffect(() => {
    if (subscriptionDoc && subscriptionVariables) {
      subscribe({
        query: subscriptionDoc,
        variables: subscriptionVariables,
      });
    }
  }, [subscriptionDoc, subscriptionVariables]);

  return {
    data,
    error,
    loading,
  };
};
