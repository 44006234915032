import { useCallback, useMemo, useState } from 'react';
import { TFiltersProps } from '../../../components/filters/Filters';
import { isDependant } from '../../../entities/recipients/shared';
import { TDependantType, TGiftStatuses, TGiftTypes } from '../../../generated/graphql';
import { GiftAssembling, TGiftAssemblingColumnDetails } from '../GiftAssembling/GiftAssembling';
import { TGroupedGiftType, useQueryAllOneOffAssemblingGifts } from '../../../entities/gifts/queries';
import { ONE_OFF_EVENT_TYPES } from '../../../entities/events/shared';

export const subscriptionGiftsAssemblingColumnsDetails: TGiftAssemblingColumnDetails[] = [
  {
    name: 'Todo',
    destinationColumns: [TGiftStatuses.Assembling],
    nextPhase: TGiftStatuses.Assembling,
    id: TGiftStatuses.Todo,
  },
  {
    name: 'Assembling',
    destinationColumns: [TGiftStatuses.Todo, TGiftStatuses.Qa],
    nextPhase: TGiftStatuses.Qa,
    id: TGiftStatuses.Assembling,
  },
  {
    name: 'QA',
    destinationColumns: [TGiftStatuses.Todo, TGiftStatuses.Assembling, TGiftStatuses.Done],
    nextPhase: TGiftStatuses.Done,
    id: TGiftStatuses.Qa,
  },
  {
    name: 'Done',
    destinationColumns: [],
    nextPhase: undefined,
    id: TGiftStatuses.Done,
  },
];

export const OneOffGiftsAssembling = () => {
  const [giftTypeFilter, setGiftTypeFilter] = useState<TGiftTypes>();
  const [recipientTypeFilter, setRecipientTypeFilter] = useState<TDependantType | 'employee'>();

  const filterMethod = useCallback(
    (gift: TGroupedGiftType) => {
      if (giftTypeFilter != null && gift.type !== giftTypeFilter) return false;

      if (recipientTypeFilter != null) {
        const recipient = gift.recipient;
        if (recipient == null) return false;

        if (isDependant(recipient)) {
          if (recipient.type !== recipientTypeFilter) return false;
        } else {
          // THIS IS AN EMPLOYEE
          if (recipientTypeFilter !== 'employee') return false;
        }
      }

      return true;
    },
    [giftTypeFilter, recipientTypeFilter],
  );

  const extraFilters = useMemo(() => {
    const filtersList: TFiltersProps['filters'] = [];

    filtersList.push(
      {
        label: 'Event Type',
        name: 'eventType',
        onChange: (v) => {
          if (v == null) setGiftTypeFilter(undefined);
          setGiftTypeFilter(v as TGiftTypes);
        },
        options: Object.entries(ONE_OFF_EVENT_TYPES).map(([key, value]) => ({ label: value, value: key })),
        type: 'singleSelect',
        value: giftTypeFilter,
      },
      {
        label: 'Recipient Type',
        name: 'recipientType',
        onChange: (v) => {
          if (v == null) setRecipientTypeFilter(undefined);
          setRecipientTypeFilter(v as TDependantType | 'employee');
        },
        options: [
          {
            label: 'Employee',
            value: 'employee',
          },
          {
            label: 'Spouse',
            value: TDependantType.SignificantOther,
          },
          {
            label: 'Child',
            value: TDependantType.Child,
          },
          {
            label: 'Pet',
            value: TDependantType.Pet,
          },
        ],
        type: 'singleSelect',
        value: recipientTypeFilter,
      },
    );

    return filtersList;
  }, [giftTypeFilter, recipientTypeFilter]);

  return (
    <GiftAssembling
      columnsDetails={subscriptionGiftsAssemblingColumnsDetails}
      extraFilterFunction={filterMethod}
      extraFilters={extraFilters}
      query={useQueryAllOneOffAssemblingGifts}
    />
  );
};
