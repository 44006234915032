import {
  ErrorPage,
  InfoCardInner,
  LoadingPage,
  Modal,
  TInfoCardSectionColumnProps,
  readableDate,
} from '@chocolate-soup-inc/cs-frontend-components';
import { useMemo } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { EMPLOYEES_PATH } from '../../../routes/paths';
import { EmployeeModal } from '../EmployeeModal/EmployeeModal';

import styles from './EmployeeAccount.module.scss';
import { TEmployeeType, useFragmentOrFetchEmployee } from '../../../entities/employees/queries';

export const EmployeeAccount = () => {
  const navigate = useNavigate();
  const { companyId, employeeId } = useParams();

  const {
    data: employee,
    error,
    loading,
  } = useFragmentOrFetchEmployee({
    id: employeeId as string,
    companyId: companyId as string,
  });

  const title = useMemo(() => {
    const fullName = employee?.fullName;
    if (fullName) return fullName;
    else return 'Employee Account';
  }, [employee?.fullName]);

  const topColumns = useMemo(() => {
    const cols: TInfoCardSectionColumnProps<TEmployeeType>[] = [
      {
        label: 'First Name',
        accessor: 'firstName',
      },
      {
        label: 'Last Name',
        accessor: 'lastName',
      },
      {
        label: 'Hire Date',
        render: (data) => (data?.hireDate ? readableDate(data.hireDate) : undefined),
      },
    ];

    if (employee?.company?.hasCustomOnboardBrick) {
      cols.push({
        label: 'Custom Onboard Brick',
        accessor: 'customOnboardBrick',
      });
    }

    return cols;
  }, [employee?.company?.hasCustomOnboardBrick]);

  if (loading || !employee) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <LoadingPage />
      </Modal>
    );
  }
  if (error) {
    return (
      <Modal closeModal={() => navigate(generatePath(EMPLOYEES_PATH))} headline={title}>
        <ErrorPage error={error} />
      </Modal>
    );
  }

  return (
    <EmployeeModal employee={employee} headline={title}>
      <div className={styles.employeeAccount}>
        <InfoCardInner<TEmployeeType>
          data={employee}
          sections={[
            {
              title: 'Information',
              columns: topColumns,
            },
            {
              columns: [
                {
                  label: 'Office',
                  render: (data?) => data?.office?.name,
                },
                {
                  label: 'Preferred First Name',
                  accessor: 'preferredFirstName',
                },
                {
                  label: 'Date of Birth',
                  render: (data) => readableDate(data?.birthDate || undefined),
                },
                {
                  label: 'Email',
                  accessor: 'email',
                },
                {
                  label: 'Phone Number',
                  accessor: 'phoneNumber',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Allergies & Dietary Restrictions',
                  accessor: 'foodPreferences',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'T-Shirt Size',
                  accessor: 'tShirtSize',
                },
                {
                  label: 'Sweater Size',
                  accessor: 'sweaterSize',
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Address Line 1',
                  render: (data) => data?.address?.address1,
                },
                {
                  label: 'Address Line 2',
                  render: (data) => data?.address?.address2,
                },
                {
                  label: 'City',
                  render: (data) => data?.address?.city,
                },
                {
                  label: 'State',
                  render: (data) => data?.address?.state,
                },
                {
                  label: 'Country',
                  render: (data) => data?.address?.country,
                },
                {
                  label: 'Zip Code',
                  render: (data) => data?.address?.zipCode,
                },
              ],
            },
            {
              columns: [
                {
                  label: 'Donate Birthday Gifts to Charity',
                  accessor: 'donateBirthdayGift',
                  render: (value) => (value ? 'yes' : 'no'),
                },
                {
                  label: 'Donate Work Anniversary Gifts to Charity',
                  accessor: 'donateWorkAnniversaryGift',
                  render: (value) => (value ? 'yes' : 'no'),
                },
              ],
            },
          ]}
        />
      </div>
    </EmployeeModal>
  );
};
